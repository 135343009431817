import React from "react";
import Svg, { Path } from "react-native-svg";
import classes from "./WavyHeader.module.css";
const WavyHeader = () => {
  return (
    <div className={classes.svgCurve}>
      <div className={classes.container}>
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          style={{ position: 'absolute', top: 50, left: 0 }}
        >
          <Path
            fill="#273036"
            fillOpacity="1"
            d="M0,224L48,208C96,192,192,160,288,144C384,128,480,128,576,117.3C672,107,768,85,864,69.3C960,53,1056,43,1152,37.3C1248,32,1344,32,1392,32L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></Path>
        </Svg>
      </div>
    </div>
  );
};

export default WavyHeader;
