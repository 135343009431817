import MUIHeader from "./MUIHeader";
import WavyHeader from "./WavyHeader";

import classes from "./Header.module.css";
const Header = () => {
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <MUIHeader />
      </div>
      <WavyHeader />
    </div>
  );
};

export default Header;
