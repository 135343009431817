import * as React from "react";
import { useState, useEffect, useCallback } from "react";
/* import { useMsal } from "@azure/msal-react"; */

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { sentenceCase } from "change-case";

import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  Card,
  Collapse,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  ListItemButton,
  ListItemText,
  CircularProgress,
} from "@mui/material";

import { accessTokenForApi } from "../../../authentication/authConfig";
import { env } from "../../../env";
import Scrollbar from "../../scrollbar/Scrollbar";
import Label from "../../label/Label";
import Iconify from "../../iconify/Iconify";
// sections
import {
  DashboardsListHead,
  DashboardsListToolbar,
} from "../../sections/@dashboard/dashboards";

const TABLE_HEAD = [
  { id: "boardname", label: "Board Name", alignRight: false },
  { id: "teamname", label: "Team Name", alignRight: false },
  { id: "officename", label: "Office Name", alignRight: false },
  { id: "location", label: "Location", alignRight: false },
  { id: "floor", label: "Floor", alignRight: false },
  { id: "owner", label: "Owner", alignRight: false },
  { id: "piuser", label: "Pi User", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "lastupdated", label: "Last Updated", alignRight: false },
  { id: "" },
];

const BASE_URL = env.REACT_APP_BACKEND_BASE_URL;

//--------------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.boardname.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

//------------------------------------------------------------------------------------

export default function RecentlyAddedScreens(props) {
  const navigate = useNavigate();
  /*  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount(); */
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newDeviceList, setNewDeviceList] = useState([]);
  const [fetchDeviceListError, setFetchDeviceListError] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("boardname");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterName, setFilterName] = useState("");

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newDeviceList.length) : 0;

  const filteredNewDevices = applySortFilter(
    newDeviceList,
    getComparator(order, orderBy),
    filterName
  );
  const isDeviceNotFound = !filteredNewDevices.length && !!filterName;

  const handleExpandListClick = () => {
    setExpanded(!expanded);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = newDeviceList.map((n) => n.piuser);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleRowClick = (rowData) => {
    navigate(`/dashboard/deviceoverview`, {
      state: {
        rowData,
      },
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  //-------------Fetch new devices list from DB-------------------------------------

  const fetchNewDeviceList = useCallback(async () => {
    setIsLoading(true);
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(BASE_URL + "/board/getNewAddedBoards", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      const transformedList = data.resultSet.map((listData) => {
        return {
          status: (!listData.active && "inactive") || "active",
          id: listData.board_id,
          boardname: listData.board_name,
          teamname: listData.team_name,
          officename: listData.office_name,
          location: listData.office_location,
          floor: listData.board_floor,
          owner: listData.team_owner,
          piuser: listData.pi_user,
          lastupdatedat: listData.updated_at,
          ipaddress: listData.ip_address,
          officeid: listData.office_id,
          teamid: listData.team_id,
        };
      });
      setNewDeviceList(transformedList);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setFetchDeviceListError(error.message);
      console.log(fetchDeviceListError);
    }
    // eslint-disable-next-line
  }, [fetchDeviceListError]);
  useEffect(() => {
    fetchNewDeviceList();
  }, [fetchNewDeviceList, props.reFetch]);
  return (
    <Card
      sx={{
        mt: 3,
        padding: 2,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ListItemButton onClick={handleExpandListClick}>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="h6">Recently Added Dashboards</Typography>
            }
          />
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <DashboardsListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          searchComponent="dashboard"
        />

        {isLoading ? (
          <Box sx={{ flexGrow: 1, textAlign: "center", paddingTop: 7 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <DashboardsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={newDeviceList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredNewDevices
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        boardname,
                        teamname,
                        officename,
                        floor,
                        owner,
                        location,
                        // eslint-disable-next-line
                        country,
                        piuser,
                        status,
                        lastupdatedat,
                        // eslint-disable-next-line
                        ipaddress,
                        // eslint-disable-next-line
                        officeid,
                        // eslint-disable-next-line
                        teamid,
                      } = row;
                      const selectedDashboard = selected.indexOf(id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          selected={selectedDashboard}
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleRowClick(row)}
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="h6" paddingLeft={2} noWrap>
                                {boardname}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{officename}</TableCell>
                          <TableCell align="left">{teamname}</TableCell>
                          <TableCell align="left">{location}</TableCell>
                          <TableCell align="left">{floor}</TableCell>
                          <TableCell align="left">{owner}</TableCell>
                          <TableCell align="left">{piuser}</TableCell>
                          <TableCell align="left">
                            <Label
                              color={
                                (status === "inactive" && "error") || "success"
                              }
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{lastupdatedat}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) =>
                                props.onPopOverMenuClick(event, row)
                              }
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isDeviceNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        )}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={newDeviceList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Collapse>
    </Card>
  );
}
