// @mui
import PropTypes from "prop-types";
import { Box, Stack, Link, Card, Typography, CardHeader } from "@mui/material";
// utils
import { fToNow } from "../../../../utils/formatTime";
// components
import Scrollbar from "../../../scrollbar/Scrollbar";

// ----------------------------------------------------------------------

AppLatestUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppLatestUpdate({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
  
      <Scrollbar height="60vh">
          <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
            {list.map((entry) => (
              <UpdateItem key={entry.id} update={entry} />
            ))}
          </Stack>
      </Scrollbar>

      <Box sx={{ p: 2, textAlign: "right" }}>
        {/*  <Button
          size="small"
          color="inherit"
          endIcon={<Iconify icon={"eva:arrow-ios-forward-fill"} />}
        >
          View all
        </Button> */}
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

UpdateItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
  }),
};

function UpdateItem({ update }) {
  const { image, title, description, postedAt } = update;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box
        component="img"
        alt={title}
        src={image}
        sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
      />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link color="inherit" variant="body1" underline="hover" noWrap>
          <Box sx={{ textTransform: "capitalize" }}>
            <strong>{title}</strong>
          </Box>
        </Link>

        <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
          {description}
        </Typography>
      </Box>

      <Typography
        variant="caption"
        sx={{ pr: 3, flexShrink: 0, color: "text.secondary" }}
      >
        {fToNow(postedAt)}
      </Typography>
    </Stack>
  );
}
