// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'add new url',
    path: '/dashboard/app',
    icon: icon('ic_add'),
  },
  /* {
    title: 'edit board title',
    path: '/dashboard/user',
    icon: icon('ic_edit'),
  }, */
 /*  {
    title: 'clone device',
    path: '/dashboard/products',
    icon: icon('ic_clone'),
  }, */
  {
    title: 'restart device',
    path: 'restart',
    icon: icon('ic_restart'),
  },
];
export default navConfig;


