import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { useState, useCallback, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { sentenceCase } from "change-case";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  /* Checkbox, */
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Modal,
  Fade,
  Backdrop,
  CircularProgress,
} from "@mui/material";
// components
import Iconify from "../../iconify/Iconify";
import Scrollbar from "../../scrollbar/Scrollbar";
import SuccessDialog from "../../UI/alerts/SuccessfulAdd";
import AlertDialog from "../../UI/alerts/AlertDialog";
import AddOffice from "./AddOffice";
import Label from "../../label/Label";
import AddFloor from "./AddFloor";
import UpdateOffice from "./UpdateOffice";
import PopOverMenu from "../../UI/PopOverMenu/PopOverMenu";
import { addRecentActivity } from "../../activityTracker/activityUpdate";
import { accessTokenForApi } from "../../../authentication/authConfig";
import { env } from "../../../env";
// sections
import {
  OfficesListHead,
  OfficesListToolbar,
} from "../../sections/@dashboard/offices";

// Global constants------------------------------------------------------

const BASE_URL = env.REACT_APP_BACKEND_BASE_URL;

const TABLE_HEAD = [
  { id: "officename", label: "Office Name", alignRight: false },
  { id: "country", label: "Country", alignRight: false },
  { id: "location", label: "Location", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "floors", label: "Floors", alignRight: false },
  { id: "addfloor" },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.officename.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const style = {
  position: "absolute",
  top: { md: "10%", lg: "40%", xl: "50%" },
  left: { md: "10%", lg: "40%", xl: "50%" },
  transform: "translate(-50%, -50%)",
  width: { md: "40vw", lg: 650, xl: 950 },
  height: { md: "30vh", lg: 350, xl: 450 },
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 54,
  p: 4,
};
const addFloorStyle = {
  position: "absolute",
  top: { md: "10%", lg: "40%", xl: "50%" },
  left: { md: "10%", lg: "40%", xl: "50%" },
  transform: "translate(-50%, -50%)",
  width: { md: "10vw", lg: "20vw", xl: 330 },
  height: { md: "20vh", lg: "30vh", xl: 250 },
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 54,
  p: 4,
};

// ----------------------------------------------------------------------

export default function OfficeOverviewPage() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("officename");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [officeList, setOfficeList] = useState([]);
  const [fetchOfficeListError, setFetchOfficeListError] = useState(null);
  const [openAddOffice, setAddOffice] = useState(false);
  const [openOfficeUpdate, SetOpenOfficeUpdate] = useState(false);

  const [officeUpdateStatus, setOfficeUpdateStatus] = useState(false);  
  const [officeAddStatus, setOfficeAddStatus] = useState(false);

  const [openDeleteOfficeConfirm, SetOpenDeleteOfficeConfirm] = useState(false);
  const [officeDeleteStatus, setOfficeDeleteStatus] = useState(false);
  const [selectedRowOfficeId, SetSelectedRowOfficeId] = useState("");
  const [selectedRowOfficeName, SetSelectedRowOfficeName] = useState("");
  const [openAddFloor, setAddFloor] = useState(false);
  const [floorAddStatus, setFloorAddStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reFetchData, setReFetchData] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dataInRow, setDataInRow] = useState([]);
  //-------------------------------------------------------------------------

  const officeStatusResetHandler = () => {
    setOfficeAddStatus(false);
    setReFetchData(!reFetchData);
  };
  const officeStatusHandler = () => {
    setOfficeAddStatus(true);
  };

  const handleOpenMenu = (event, id, row) => {
    event.stopPropagation();
    setOpen(event.currentTarget);
    SetSelectedRowOfficeId(id);
    SetSelectedRowOfficeName(row.officename);
    setDataInRow(row);
  };

  const floorStatusResetHandler = () => {
    setFloorAddStatus(false);
    setReFetchData(!reFetchData);
  };
  const handleAddFloor = (event, id, officeName) => {
    event.stopPropagation();
    setAddFloor(true);
    SetSelectedRowOfficeId(id);
    SetSelectedRowOfficeName(officeName);
  };
  const handleCloseAddFloor = () => {
    setAddFloor(false);
  };
  const addFloorStatusHandler = () => {
    setFloorAddStatus(true);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  /*   const handleCheckboxClick = (e) => {
    e.stopPropagation();
  }; */

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = officeList.map((n) => n.officename);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  /*  const handleClick = (event, officename) => {
    const selectedIndex = selected.indexOf(officename);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, officename);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  }; */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleAddOffice = () => {
    setAddOffice(true);
  };
  const handleCloseOffice = () => {
    setAddOffice(false);
  };
  const handleRowClick = (rowData) => {
    navigate(`/dashboard/officedetails`, {
      state: {
        rowData,
      },
    });
  };

  const handleOfficeDelete = () => {
    SetOpenDeleteOfficeConfirm(true);
    setOpen(null);
  };

  const handleOfficeUpdate = () => {
    SetOpenOfficeUpdate(true);
  };
  const handleCloseOfficeUpdate = () => {
    SetOpenOfficeUpdate(false);
    setOpen(null);
  };
  const officeUpdateStatusHandler = () => {
    setOfficeUpdateStatus(true);
  };

  const officeUpdateStatusResetHandler = () => {
    setOfficeUpdateStatus(false);
    setReFetchData(!reFetchData);
  };


  const openDeleteOfficeConfirmResetHandler = () => {
    SetOpenDeleteOfficeConfirm(false);
  };

  const officeDeleteStatusHandler = () => {
    setOfficeDeleteStatus(true);
  };

  const officeDeleteStatusResetHandler = () => {
    setOfficeDeleteStatus(false);
    setSelected([]);
    setReFetchData(!reFetchData);
  };

  //-------------------------------------------------------------------------------

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - officeList.length) : 0;

  const filteredOffices = applySortFilter(
    officeList,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredOffices.length && !!filterName;

  //----------------------------------------------------------------------------------

  const deleteOfficeHandler = useCallback(async (officeId, officeName) => {
    const operation = {
      operations_performed: "Deleted",
      component_type: "office",
      component_name: officeName,
      update_by: activeAccount.name,
    };
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(
        BASE_URL + `/office/deleteOffice/${officeId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authenticatedValue.accessToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      if (data.Status === "success") {
        addRecentActivity(operation);
        openDeleteOfficeConfirmResetHandler();
        officeDeleteStatusHandler();
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  const fetchOfficeList = useCallback(async () => {
    const authenticatedValue = await accessTokenForApi();
    if (authenticatedValue.roles.indexOf("App.Admin") > -1) {
      setIsAdmin(true);
    }
    setIsLoading(true);
    setFetchOfficeListError(null);
    try {
      const response = await fetch(BASE_URL + "/office/getAllOffice", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();

      const transformedList = data.resultSet.data.map((listData) => {
        return {
          officename: listData.office_name,
          country: listData.office_country,
          id: listData.office_id,
          location: listData.office_location,
          floors: listData.office_floor,
          status: (!listData.active && "inactive") || "active",
        };
      });
      setOfficeList(transformedList);
      setIsLoading(false);
    } catch (error) {
      setFetchOfficeListError(error.message);
      setIsLoading(false);
      console.log(fetchOfficeListError);
    }
  }, [fetchOfficeListError]);

  useEffect(() => {
    fetchOfficeList();
  }, [fetchOfficeList, reFetchData]);

  // ----------------------------------------------------------------------

  return (
    <>
      <Helmet>
        <title> Offices | Dashboard Insights </title>
      </Helmet>

      <Box sx={{ padding: 5 }}>
        <CssBaseline />
        <Box>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            sx={{ width: "100vw" }}
            open={openOfficeUpdate}
            closeAfterTransition
            onClose={handleCloseOfficeUpdate}
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={openOfficeUpdate}>
              <Box sx={style}>
                <UpdateOffice
                   datas={dataInRow}
                   onUpdateClick={officeUpdateStatusHandler}
                   onCancelClick={handleCloseOfficeUpdate}
                />
              </Box>
            </Fade>
          </Modal>
          {officeUpdateStatus && (
          <SuccessDialog
            open={officeUpdateStatus}
            component="Office"
            operation="updated"
            onOkClick={officeUpdateStatusResetHandler}
          />
        )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                paddingTop: 3,
                color: "#022F8E",
                fontSize: { xs: "none", md: 16, lg: 20, xl: 24 },
              }}
            >
              Offices
            </Typography>
            {isAdmin && (
              <Button
                variant="contained"
                onClick={handleAddOffice}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Office
              </Button>
            )}
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              sx={{ width: "100vw" }}
              open={openAddOffice}
              closeAfterTransition
              onClose={handleCloseOffice}
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={openAddOffice}>
                <Box sx={style}>
                  <AddOffice
                    onCancelClick={handleCloseOffice}
                    onAddClick={officeStatusHandler}
                  />
                </Box>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              sx={{ width: "100vw" }}
              open={openAddFloor}
              closeAfterTransition
              onClose={handleCloseAddFloor}
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={openAddFloor}>
                <Box sx={addFloorStyle}>
                  <AddFloor
                    onCancelClick={handleCloseAddFloor}
                    onAddClick={addFloorStatusHandler}
                    officeId={selectedRowOfficeId}
                    officeName={selectedRowOfficeName}
                  />
                </Box>
              </Fade>
            </Modal>
          </Stack>
          {floorAddStatus && (
            <SuccessDialog
              open={floorAddStatus}
              component="Floor"
              operation="added"
              onOkClick={floorStatusResetHandler}
            />
          )}
          {officeAddStatus && (
            <SuccessDialog
              open={officeAddStatus}
              component="Office"
              operation="added"
              onOkClick={officeStatusResetHandler}
            />
          )}
          {openDeleteOfficeConfirm && (
            <AlertDialog
              open={openDeleteOfficeConfirm}
              component="Office"
              operation="delete"
              name={selectedRowOfficeName}
              onCancelClick={openDeleteOfficeConfirmResetHandler}
              onOkClick={() =>
                deleteOfficeHandler(selectedRowOfficeId, selectedRowOfficeName)
              }
            />
          )}
          {officeDeleteStatus && (
            <SuccessDialog
              open={officeDeleteStatus}
              component="Office"
              operation="deleted"
              onOkClick={officeDeleteStatusResetHandler}
            />
          )}
          <Card>
            <OfficesListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
            {isLoading ? (
              <Box sx={{ flexGrow: 1, textAlign: "center", paddingTop: 7 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <OfficesListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={officeList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredOffices
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const {
                            officename,
                            country,
                            id,
                            location,
                            floors,
                            status,
                          } = row;
                          const selectedUser =
                            selected.indexOf(officename) !== -1;
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              sx={{
                                cursor: "pointer",
                              }}
                              selected={selectedUser}
                              onClick={() => handleRowClick(row)}
                            >
                              {/* <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedUser}
                                  onChange={(event) =>
                                    handleClick(event, officename)
                                  }
                                  onClick={(e) => handleCheckboxClick(e)}
                                />
                              </TableCell> */}
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                <Typography variant="h6" paddingLeft={2} noWrap>
                                  {officename}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">{country}</TableCell>
                              <TableCell align="left">{location}</TableCell>
                              <TableCell align="left">
                                <Label
                                  color={
                                    (status === "inactive" && "error") ||
                                    "success"
                                  }
                                >
                                  {sentenceCase(status)}
                                </Label>
                              </TableCell>
                              <TableCell align="left">
                                {floors.join(", ")}
                              </TableCell>
                              <TableCell align="center">
                                {isAdmin && (
                                  <Button
                                    variant="outlined"
                                    endIcon={
                                      <Iconify
                                        icon={"mdi:map-marker-plus-outline"}
                                      />
                                    }
                                    onClick={(e) =>
                                      handleAddFloor(e, id, officename)
                                    }
                                  >
                                    Add Floor
                                  </Button>
                                )}
                              </TableCell>

                              {isAdmin && (
                                <TableCell align="right">
                                  <IconButton
                                    size="large"
                                    color="inherit"
                                    onClick={(e) =>
                                      handleOpenMenu(e, id, row)
                                    }
                                  >
                                    <Iconify icon={"eva:more-vertical-fill"} />
                                  </IconButton>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete
                                words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
            )}

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={officeList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
        <PopOverMenu
          openStatus={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
          handleUpdateOperation={handleOfficeUpdate} 
          handleDeleteOperation={handleOfficeDelete}
        />
      </Box>
    </>
  );
}
