import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useState, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
// @Mui
import {
  CssBaseline,
  Typography,
  Grid,
  Stack,
  Box,
  FormGroup,
  FormControlLabel,
  styled,
  Switch,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
//components
import InputTextField from "../../UI/FormInput/InputTextField";
import InputSelectList from "../../UI/FormInput/InputSelectList";
import Iconify from "../../iconify/Iconify";
//datas
import CountryAndRegionList from "../../data/CountryAndLocationList";
import { accessTokenForApi } from "../../../authentication/authConfig";
import { addRecentActivity } from "../../activityTracker/activityUpdate";
import { env } from "../../../env";
//-----------------------------------------------------------------------------------------

const StatusSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

//--------------------------------------------------------------------------

const BASE_URL = env.REACT_APP_BACKEND_BASE_URL;

const countries = CountryAndRegionList.countries;

const regions = CountryAndRegionList.regions;

//---------------------------------------------------------------------------

const AddOffice = (props) => {
  
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
 //---------------------------------------------------------------
  const theme = useTheme();

  const [checked, setChecked] = useState(false);
  const [officeName, setOfficeName] = useState("");
  const [country, setCountry] = useState("");
  const [location, setLocation] = useState("");
  const [floor, setFloor] = useState("");
  const [officeNameError, setOfficeNameError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [floorError, setFloorError] = useState(false);
  const [addOfficeError, setAddOfficeError] = useState(null);
  const [ofcErrorMsg, setOfcErrorMsg] = useState("");
  const [floorErrorMsg, setFloorErrorMsg] = useState("");
  const [locDisabled, setLocDisabled] = useState(true);
  const [divData, setDivData] = useState([]);
  const [officeAlreadyExist, setOfficeAlreadyExist] = useState(false);

  let formIsValid = false;

  //-----------------------------------------------------

  if (
    officeName &&
    country &&
    location &&
    floor &&
    !officeNameError &&
    !countryError &&
    !locationError &&
    !floorError
  ) {
    formIsValid = true;
  }
  const handleOfficeChange = (event) => {
    if (officeAlreadyExist) {
      setOfficeAlreadyExist(false);
    }
    if (event.target.value && !event.target.value.match(/^[a-zA-Z0-9- ]+$/)) {
      setOfficeNameError(true);
      setOfcErrorMsg("Only letters allowed");
    } else {
      setOfficeNameError(false);
      setOfcErrorMsg("");
    }
    setOfficeName(event.target.value);
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
  const getData = useCallback((country, region) => {
    let arr = [];

    if (country) {
      arr = regions[country];
    } else {
      arr = countries;
    }

    return arr;
  }, []);

  const handleSelectChange = useCallback(
    (event) => {
      setCountry(event.target.value);
      setDivData(getData(event.target.value, null));
      setLocDisabled(false);
    },
    [getData]
  );

  const handleSelectLocChange = (event) => {
    setLocation(event.target.value);
  };

  const handleFloorChange = (event) => {
    if (event.target.value && !event.target.value.match(/^[0-9]+$/)) {
      setFloorError(true);
      setFloorErrorMsg("Only one floor at a time");
    } else {
      setFloorError(false);
      setFloorErrorMsg("");
    }
    setFloor(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setOfficeNameError(false);
    setCountryError(false);
    setLocationError(false);
    setFloorError(false);

    if (officeName === "") {
      setOfcErrorMsg("Invalid Office name");
      setOfficeNameError(true);
    }
    if (country === "") {
      setCountryError(true);
    }
    if (location === "") {
      setLocationError(true);
    }
    if (floor === "") {
      setFloorErrorMsg("Invalid floor");
      setFloorError(true);
    }

    if (
      officeName &&
      country &&
      location &&
      floor &&
      !officeNameError &&
      !countryError &&
      !locationError &&
      !floorError
    ) {
      formIsValid = true;
      const office = {
        office_name: officeName,
        office_country: country,
        office_location: location,
        office_floor: floor,
        active: checked,
        updated_by: activeAccount.username
      };
      const operation = {
        operations_performed: "Added",
        component_type: "office",
        component_name: officeName,
        update_by:  activeAccount.name,
      };
      addOfficeHandler(office, operation);
    }
  };

  const addOfficeHandler = async (office, operation) => {
    setAddOfficeError(null);
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(BASE_URL + "/office/addOffice", {
        method: "POST",
        body: JSON.stringify(office),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      const data = await response.json();
      if (data.Status === "success") {
        props.onCancelClick();
        props.onAddClick();
        addRecentActivity(operation);
      } else if (data.message.includes("already exist")) {
        setOfficeAlreadyExist(true);
      }
    } catch (error) {
      setAddOfficeError(error.message || "Something went wrong!");
      console.log(addOfficeError);
    }
  };

  return (
    <>
      <Helmet>
        <title> Add Office | Office </title>
      </Helmet>
      <CssBaseline />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            variant="h4"
            noWrap
            sx={{
              display: { xs: "none", md: "flex" },
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: { xs: "none", md: 16, lg: 20, xl: 22 },
              letterSpacing: "0rem",
              color: "text.primary",
              textDecoration: "none",
            }}
          >
            Add new Office
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            sx={{
              display: "flex",
              columnGap: { xs: "none", md: 0, lg: 3, xl: 7 },
            }}
          >
            <Grid
              item
              sx={{ marginTop: { sm: "none", md: "none", lg: "none", xl: 1 } }}
            >
              <Box sx={{ width: 500 }}>
                <form autoComplete="off" onSubmit={submitHandler}>
                  <Stack direction="row">
                    <InputTextField
                      placeholder="Enter office name"
                      id="officename"
                      label="Office Name"
                      helperText={
                        officeNameError ? ofcErrorMsg : "Name of company"
                      }
                      onChange={(e) => handleOfficeChange(e)}
                      error={officeNameError}
                    />
                    <InputSelectList
                      name="countries"
                      id={props.name + "-select"}
                      value={country}
                      label="Country"
                      onChange={handleSelectChange}
                      width="200"
                      values={getData(null, null)}
                      error={countryError}
                      helperText="Country of office"
                    />
                  </Stack>

                  <Stack direction="row">
                    <InputSelectList
                      name="location"
                      id={props.name + "-select"}
                      value={location}
                      label="Location"
                      onChange={handleSelectLocChange}
                      width="200"
                      disabled={locDisabled}
                      values={divData}
                      error={locationError}
                      helperText="Office location"
                    />
                    <InputTextField
                      placeholder="Enter floor number"
                      id="floor"
                      label="Floor"
                      helperText={
                        floorError ? floorErrorMsg : "Add floor number"
                      }
                      onChange={(e) => handleFloorChange(e)}
                      error={floorError}
                    />
                  </Stack>
                  <Stack direction="row" mt={1} spacing={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <StatusSwitch
                            sx={{ m: 2 }}
                            checked={checked}
                            onChange={toggleChecked}
                          />
                        }
                        label={`${checked ? "Active" : "Inactive"}`}
                      />
                    </FormGroup>
                  </Stack>
                  <Stack
                    direction="row"
                    ml={1}
                    sx={{ mt: { xs: "none", md: "none", lg: "none", xl: 2 } }}
                    spacing={10}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      disabled={!formIsValid}
                    >
                      Add
                    </Button>
                    <Button variant="contained" onClick={props.onCancelClick}>
                      Cancel
                    </Button>
                  </Stack>
                </form>
              </Box>
              {officeAlreadyExist && (
                <Typography
                  variant="caption"
                  pt={5}
                  gutterBottom
                  sx={{
                    color: "#FF0000",
                    //fontSize: { xs: "none", md: 16, lg: 22, xl: 22 },
                  }}
                >
                  Office already exist. Please try with a unique name
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Box
                component="img"
                alt="dashboardIcon"
                src="/assets/images/illustrators/office_illustration_building.png"
                sx={{
                  [theme.breakpoints.up("sm")]: {
                    display: "none",
                  },
                  [theme.breakpoints.up("md")]: {
                    display: "none",
                  },
                  [theme.breakpoints.up("lg")]: {
                    display: "none",
                  },
                  [theme.breakpoints.up("xl")]: {
                    display: "flex",
                    width: 250,
                    height: 260,
                  },
                  flexShrink: 1,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddOffice;
