import React from "react";

const OfficeContext = React.createContext(
    {
        officeId: "",
        officeName: "",
        selectedOfficeLocation: [],
        isRedirectFromOffice: false
    }
)

export default OfficeContext;