//react components
import { Grid, Typography, Box, Card } from "@mui/material";
import PropTypes from "prop-types";
//components
import dashboard from "../../../data/Dashboard";
import Label from "../../../label/Label";
DeviceDetailsCard.propTypes = {
  data: PropTypes.array,
};

export default function DeviceDetailsCard(props) {
  let data = props.data;
  return (
    <>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ flexGrow: 1, rowGap: 2.5 }}
        >
          <Grid item md={8}>
            <Grid container direction="row" sx={{ rowGap: 1.5 }}>
              {data.map((item) => (
                <Grid item xl={6} key={item.title}>
                  <CardItem key={item.title} item={item} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: { md: 3, lg: 3, xl: 5 }, ml: { xl: 3 }, rowGap: 1 }}
            >
              <Grid item paddingTop={1}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <Box
                      component="img"
                      alt="dashboardIcon"
                      src={dashboard.photoURL}
                      sx={{
                        width: { md: 50, xl: 70 },
                        height: { md: 50, xl: 70 },
                        borderRadius: 1.5,
                        flexShrink: 0,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.primary",

                        fontSize: { md: 16, xl: 18 },
                      }}
                    >
                      {props.piuser}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Label color={"success"}>{props.lastupdated}</Label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </>
  );
}
function CardItem({ item }) {
  const { title, icon, value } = item;

  return (
    <Card
      sx={{
        background: "transparent",
        color: "#022F8E",
        paddingTop: { xs: 1, md: 0.5, xl: 1 },
        paddingLeft: { xs: 1, md: 0.5, xl: 1 },
        borderRadius: 1,
        boxShadow: 5,
        width: { md: "none", lg: 235, xl: 255 },
        height: { md: "none", lg: 90, xl: 100 },
      }}
    >
      <Grid container direction="row">
        <Grid item>
          <Box
            component="img"
            alt={title}
            src={icon}
            sx={{
              mt: { md: 0.5, lg: 0.5, xl: 2.5 },
              width: { md: 20, lg: 30, xl: 38 },
              height: { md: 20, lg: 30, xl: 38 },
              flexShrink: 0,
            }}
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  color: "text.secondary",
                  paddingTop: 1,
                  paddingLeft: 2,
                  fontSize: { md: 12, lg: 12, xl: 14 },
                }}
                noWrap
              >
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                sx={{
                  color: "#026440",
                  paddingLeft: { md: 4, xl: 2 },
                  fontSize: { md: 10, lg: 10, xl: 12 },
                }}
                noWrap
              >
                {value}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
