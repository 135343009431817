import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect, useCallback } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useMsal } from "@azure/msal-react";
//import {InteractionRequiredAuthError} from "@azure/msal-browser";
// @mui
import {
  Box,
  Modal,
  Fade,
  Card,
  Table,
  Stack,
  Paper,
  Backdrop,
  Collapse,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  ListItemText,
  ListItemButton,
  CssBaseline,
  CircularProgress,
} from "@mui/material";
// components
import AddTeam from "./AddTeam";
import Iconify from "../../iconify/Iconify";
import Scrollbar from "../../scrollbar/Scrollbar";
import Label from "../../label/Label";
import SuccessDialog from "../../UI/alerts/SuccessfulAdd";
import AlertDialog from "../../UI/alerts/AlertDialog";
import PopOverMenu from "../../UI/PopOverMenu/PopOverMenu";
import UpdateTeam from "./UpdateTeam";
import { accessTokenForApi } from "../../../authentication/authConfig";
import { addRecentActivity } from "../../activityTracker/activityUpdate";
import { env } from "../../../env";
// sections
import {
  TeamsListHead,
  TeamsListToolbar,
} from "../../sections/@dashboard/teams";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "teamname", label: "Team Name", alignCenter: false },
  { id: "teamowner", label: "Team Owner", alignCenter: false },
  { id: "status", label: "Status", alignCenter: false },
  { id: "activedashboards", label: "Active Dashboards", alignCenter: true },
  { id: "totaldashboards", label: "Total Dashboards", alignCenter: true },
  { id: "" },
];

const TABLE_HEAD_NOACTIVE_BOARD = [
  { id: "teamname", label: "Team Name", alignCenter: false },
  { id: "teamowner", label: "Team Owner", alignCenter: false },
  { id: "status", label: "Status", alignCenter: false },
  { id: "" },
];

const style = {
  position: "absolute",
  top: { md: "10%", lg: "40%", xl: "50%" },
  left: { md: "10%", lg: "40%", xl: "50%" },
  transform: "translate(-50%, -50%)",
  width: { md: "40vw", lg: 600, xl: 800 },
  height: { md: "30vh", lg: 330, xl: 370 },
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 54,
  p: 4,
};

const BASE_URL = env.REACT_APP_BACKEND_BASE_URL;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.teamname.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

//-------------------------------------------------------------------------------

export default function TeamsOverviewPage() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [selectedTeamsWithoutBoard, setSelectedTeamsWithoutBoard] = useState(
    []
  );
  const [orderBy, setOrderBy] = useState("teamname");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [teamsList, setTeamsList] = useState([]);
  const [teamsWithoutBoardsList, setTeamsWithoutBoardsList] = useState([]);
  const [teamAddStatus, setTeamAddStatus] = useState(false);
  const [openDeleteTeamConfirm, SetOpenDeleteTeamConfirm] = useState(false);
  const [selectedRowTeamId, SetSelectedRowTeamId] = useState("");
  const [selectedRowTeamName, SetSelectedRowTeamName] = useState("");
  const [teamDeleteStatus, setTeamDeleteStatus] = useState(false);
  const [expandedTeamsWithBoards, setExpandedTeamsWithBoards] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [openAddTeam, setAddTeam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reFetchData, setReFetchData] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoadingWithoutBoards, setIsLoadingWithoutBoards] = useState(false);
  const [dataInRow, setDataInRow] = useState([]);
  const [openTeamUpdate, SetOpenTeamUpdate] = useState(false);
  const [teamUpdateStatus, setTeamUpdateStatus] = useState(false); 

  //------------------------------------------------------------------------------

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - teamsList.length) : 0;

  const filteredTeams = applySortFilter(
    teamsList,
    getComparator(order, orderBy),
    filterName
  );
  const filteredTeamsWithoutBoards = applySortFilter(
    teamsWithoutBoardsList,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredTeams.length && !!filterName;

  //-----------------------------------------------------------------------------------

  const handleOpenMenu = (event, id, row) => {
    setOpen(event.currentTarget);
    SetSelectedRowTeamId(id);
    SetSelectedRowTeamName(row.teamname);
    setDataInRow(row);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = teamsList.map((n) => n.teamname);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleSelectAllWithoutBoardsClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = teamsWithoutBoardsList.map((n) => n.teamname);
      setSelectedTeamsWithoutBoard(newSelecteds);
      return;
    }
    setSelectedTeamsWithoutBoard([]);
  };
 /*  const handleClick = (event, teamname) => {
    const selectedIndex = selected.indexOf(teamname);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, teamname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  }; 
   const handleClickForWithoutBoards = (event, teamname) => {
    const selectedIndex = selectedTeamsWithoutBoard.indexOf(teamname);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTeamsWithoutBoard, teamname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTeamsWithoutBoard.slice(1));
    } else if (selectedIndex === selectedTeamsWithoutBoard.length - 1) {
      newSelected = newSelected.concat(selectedTeamsWithoutBoard.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTeamsWithoutBoard.slice(0, selectedIndex),
        selectedTeamsWithoutBoard.slice(selectedIndex + 1)
      );
    }
    setSelectedTeamsWithoutBoard(newSelected);
  }; */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const teamAddStatusResetHandler = () => {
    setTeamAddStatus(false);
    setReFetchData(!reFetchData);
  };
  const teamAddStatusHandler = () => {
    setTeamAddStatus(true);
    setExpanded(true);
  };
  const teamDeleteStatusResetHandler = () => {
    setTeamDeleteStatus(false);
    setSelected([]);
    setReFetchData(!reFetchData);
  };
  const teamDeleteStatusHandler = () => {
    setTeamDeleteStatus(true);
  };

  const handleTeamDelete = () => {
    SetOpenDeleteTeamConfirm(true);
    setOpen(null);
  };
  const openDeleteTeamConfirmResetHandler = () => {
    SetOpenDeleteTeamConfirm(false);
  };
  const handleAddTeam = () => {
    setAddTeam(true);
  };
  const handleCloseTeam = () => {
    setAddTeam(false);
  };

  const handleExpandListClickOnWithBoards = () => {
    setExpandedTeamsWithBoards(!expandedTeamsWithBoards);
  };
  const handleTeamUpdate = () => {
    SetOpenTeamUpdate(true);
  };

  const handleCloseTeamUpdate = () => {
    SetOpenTeamUpdate(false);
    setOpen(null);
  };
  const teamUpdateStatusHandler = () => {
    setTeamUpdateStatus(true);
  };
  const teamUpdateStatusResetHandler = () => {
    setTeamUpdateStatus(false);
    setReFetchData(!reFetchData);
  };


  //----------------------------------------------------------------------------------------

  const deleteTeamHandler = useCallback(async (teamId, teamName) => {
    const operation = {
      operations_performed: "Deleted",
      component_type: "team",
      component_name: teamName,
      update_by: activeAccount.name,
    };
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(BASE_URL + `/team/deleteTeam/${teamId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });

      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      if (data.Status === "success") {
        addRecentActivity(operation);
        openDeleteTeamConfirmResetHandler();
        teamDeleteStatusHandler();
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  //-------------------------------------------------------------------------
  const fetchTeamsList = useCallback(async () => {
    const authenticatedValue = await accessTokenForApi();
    setIsLoading(true);
    if (authenticatedValue.roles.indexOf("App.Admin") > -1) {
      setIsAdmin(true);
    }
    try {
      const response = await fetch(BASE_URL + "/team/getAllTeamWithboards", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (response.status === 404) {
        setIsLoading(false);
      } else {
        const data = await response.json();

        const transformedList = data.resultSet.data.map((listData) => {
          return {
            id: listData.team_id,
            status: "active",
            activedashboards: listData.activeBoard,
            teamname: listData.team_name,
            teamowner: listData.team_owner,
            totaldashboards: listData.total,
          };
        });
        setTeamsList(transformedList);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(true);
    }
    // eslint-disable-next-line
  }, [reFetchData]);

  const fetchTeamsWithoutActiveBoardsList = useCallback(async () => {
    setIsLoadingWithoutBoards(true);
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(BASE_URL + "/team/getTeamsWithoutBoards", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (response.status === 404) {
        setIsLoadingWithoutBoards(false);
      } else {
        const data = await response.json();

        const transformedList = data.resultSet.data.map((listData) => {
          return {
            id: listData.team_id,
            status: (!listData.active && "inactive") || "active",
            teamowner: listData.team_owner,
            teamname: listData.team_name,
          };
        });
        setTeamsWithoutBoardsList(transformedList);
        setIsLoadingWithoutBoards(false);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  const handleExpandListClick = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    fetchTeamsList();
    fetchTeamsWithoutActiveBoardsList();
  }, [fetchTeamsList, fetchTeamsWithoutActiveBoardsList]);

  return (
    <>
      <Helmet>
        <title> Teams | Dashboard Insights </title>
      </Helmet>
      <Box sx={{ padding: 5 }}>
        <CssBaseline />
        <Box>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            sx={{ width: "100vw" }}
            open={openTeamUpdate}
            closeAfterTransition
            onClose={handleCloseTeamUpdate}
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={openTeamUpdate}>
              <Box sx={style}>
                <UpdateTeam
                   datas={dataInRow}
                   onUpdateClick={teamUpdateStatusHandler}
                   onCancelClick={handleCloseTeamUpdate}
                />
              </Box>
            </Fade>
          </Modal>
          {teamUpdateStatus && (
          <SuccessDialog
            open={teamUpdateStatus}
            component="Team"
            operation="updated"
            onOkClick={teamUpdateStatusResetHandler}
          />
        )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                paddingTop: 3,
                color: "#022F8E",
                fontSize: { xs: "none", md: 16, lg: 20, xl: 24 },
              }}
            >
              Teams
            </Typography>
            {isAdmin && (
              <Button
                variant="contained"
                onClick={handleAddTeam}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Team
              </Button>
            )}
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              sx={{ width: "100vw" }}
              open={openAddTeam}
              closeAfterTransition
              onClose={handleCloseTeam}
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={openAddTeam}>
                <Box sx={style}>
                  <AddTeam
                    onCancelClick={handleCloseTeam}
                    onAddClick={teamAddStatusHandler}
                  />
                </Box>
              </Fade>
            </Modal>
          </Stack>

          {teamAddStatus && (
            <SuccessDialog
              open={teamAddStatus}
              component="Team"
              operation="added"
              onOkClick={teamAddStatusResetHandler}
            />
          )}
          {openDeleteTeamConfirm && (
            <AlertDialog
              open={openDeleteTeamConfirm}
              component="Team"
              operation="delete"
              name={selectedRowTeamName}
              onCancelClick={openDeleteTeamConfirmResetHandler}
              onOkClick={() =>
                deleteTeamHandler(selectedRowTeamId, selectedRowTeamName)
              }
            />
          )}
          {teamDeleteStatus && (
            <SuccessDialog
              open={teamDeleteStatus}
              component="Team"
              operation="deleted"
              onOkClick={teamDeleteStatusResetHandler}
            />
          )}
          <Card
            sx={{
              mt: 3,
              padding: 2,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <ListItemButton onClick={handleExpandListClickOnWithBoards}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="h6">
                      Teams with active boards
                    </Typography>
                  }
                />
                {expandedTeamsWithBoards ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItemButton>
            </Stack>
            <Collapse in={expandedTeamsWithBoards} timeout="auto" unmountOnExit>
              {isLoading ? (
                <Box sx={{ flexGrow: 1, textAlign: "center", paddingTop: 7 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <TeamsListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                  />
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <TeamsListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={teamsList.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {filteredTeams
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              const {
                                id,
                                teamname,
                                teamowner,
                                activedashboards,
                                status,
                                /* avatarUrl, */
                                totaldashboards,
                              } = row;
                              const selectedUser =
                                selected.indexOf(teamname) !== -1;

                              return (
                                <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                  /* role="checkbox" */
                                  selected={selectedUser}
                                >
                                  {/* <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={selectedUser}
                                      onChange={(event) =>
                                        handleClick(event, teamname)
                                      }
                                    />
                                  </TableCell>
 */}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      {/* <Avatar alt={teamname} src={avatarUrl} /> */}
                                      <Typography variant="h6" paddingLeft={2} noWrap>
                                        {teamname}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell align="left">
                                    {teamowner}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Label
                                      color={
                                        (status === "inactive" && "error") ||
                                        "success"
                                      }
                                    >
                                      {sentenceCase(status)}
                                    </Label>
                                  </TableCell>
                                  <TableCell align="center">
                                    {activedashboards}
                                  </TableCell>
                                  <TableCell align="center">
                                    {totaldashboards}
                                  </TableCell>
                                  {isAdmin && (
                                    <TableCell align="right">
                                      <IconButton
                                        size="large"
                                        color="inherit"
                                        onClick={(e) =>
                                          handleOpenMenu(e, id, row)
                                        }
                                      >
                                        <Iconify
                                          icon={"eva:more-vertical-fill"}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>

                        {isNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={6}
                                sx={{ py: 3 }}
                              >
                                <Paper
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  <Typography variant="h6" paragraph>
                                    Not found
                                  </Typography>

                                  <Typography variant="body2">
                                    No results found for &nbsp;
                                    <strong>&quot;{filterName}&quot;</strong>.
                                    <br /> Try checking for typos or using
                                    complete words.
                                  </Typography>
                                </Paper>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </>
              )}
            </Collapse>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={teamsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          <Card
            sx={{
              mt: 3,
              padding: 2,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <ListItemButton onClick={handleExpandListClick}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="h6">
                      Teams without active boards
                    </Typography>
                  }
                />
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </Stack>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {isLoadingWithoutBoards ? (
                <Box sx={{ flexGrow: 1, textAlign: "center", paddingTop: 7 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TeamsListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD_NOACTIVE_BOARD}
                        rowCount={teamsWithoutBoardsList.length}
                        numSelected={selectedTeamsWithoutBoard.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllWithoutBoardsClick}
                      />
                      <TableBody>
                        {filteredTeamsWithoutBoards
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            const {
                              id,
                              teamname,
                              status,
                             /*  avatarUrl, */
                              teamowner,
                            } = row;
                            const selectedUser =
                              selectedTeamsWithoutBoard.indexOf(teamname) !==
                              -1;

                            return (
                              <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                /* role="checkbox" */
                                selected={selectedUser}
                              >
                              {/*   <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={selectedUser}
                                    onChange={(event) =>
                                      handleClickForWithoutBoards(
                                        event,
                                        teamname
                                      )
                                    }
                                  />
                                </TableCell> */}

                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Typography variant="h6" paddingLeft={2} noWrap>
                                      {teamname}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{teamowner}</TableCell>
                                <TableCell align="left">
                                  <Label
                                    color={
                                      (status === "inactive" && "error") ||
                                      "success"
                                    }
                                  >
                                    {sentenceCase(status)}
                                  </Label>
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    size="large"
                                    color="inherit"
                                    onClick={(e) =>
                                      handleOpenMenu(e, id, row)
                                    }
                                  >
                                    <Iconify icon={"eva:more-vertical-fill"} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <Paper
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>

                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using
                                  complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>
              )}
            </Collapse>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={selectedTeamsWithoutBoard.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>

        <PopOverMenu
          openStatus={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
          handleUpdateOperation={handleTeamUpdate}
          handleDeleteOperation={handleTeamDelete} 
        />
      </Box>
    </>
  );
}
