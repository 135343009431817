import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { sentenceCase } from "change-case";


export default function AlertDialogSlide(props) {
  const handleClose = () => {  
    props.onOkClick();
  };
  const handleCancelClose = () => {  
    props.onCancelClick();
  };
  return (
    <div>
      <Dialog
        open={props.open}
        keepMounted
        onClose={handleCancelClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Do you want to {props.operation}?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you really want to {props.operation} {props.component} - {props.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} startIcon={!props.isEnable ? (props.operation==="restart" ? <RestartAltIcon /> : <DeleteIcon />) : "" }>{sentenceCase(props.operation)}</Button>
          <Button onClick={handleCancelClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}