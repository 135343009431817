// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Alert } from "@mui/material";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";
// utils
import { fNumber } from "../../../../utils/FormatNumber";
// components
import Iconify from "../../../iconify/Iconify";

// ----------------------------------------------------------------------

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

HomeSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number,
  sx: PropTypes.object,
};

export default function HomeSummary({
  component,
  title,
  total,
  icon,
  error,
  color = "primary",
  sx,
  ...other
}) {
  return (
    <Card
      sx={{
        height: 250,
        boxShadow: 2,
        textAlign: "center",
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <CardActionArea component={Link} to={"/dashboard/"+component}>
        <CardContent sx={{
        height: 250,
      }}>
          <StyledIcon
            sx={{
              color: (theme) => theme.palette[color].dark,
              backgroundImage: (theme) =>
                `linear-gradient(135deg, ${alpha(
                  theme.palette[color].dark,
                  0
                )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
            }}
          >
            <Iconify icon={icon} width={24} height={24} />
          </StyledIcon>
          {error && (<Alert severity="error">
           {error}
          </Alert>)}
          {!error && (
            <Typography variant="h3">{fNumber(total)}</Typography>
          )}
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            {title}
          </Typography>

        </CardContent>
      </CardActionArea>
    </Card>
  );
}
