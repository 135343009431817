import * as React from "react";
import { Popover, MenuItem } from "@mui/material";

import Iconify from "../../iconify/Iconify";
export default function PopOverMenu(props) {
  return (
    <Popover
      open={props.openStatus}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={props.anchorOrigin}
      transformOrigin={props.transformOrigin}
      PaperProps={props.PaperProps}
    >
      <MenuItem onClick={props.handleUpdateOperation}>
        <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
        Edit
      </MenuItem>
      <MenuItem sx={{ color: "error.main" }} onClick={props.handleDeleteOperation}>
        <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
        Delete
      </MenuItem>
    </Popover>
  );
}
