import { Helmet } from "react-helmet-async";
import { useState, useEffect, useCallback } from "react";
// @mui
import {
  Box,
  List,
  Drawer,
  Divider,
  CssBaseline,
  ListItem,
  Typography,
  ListItemButton,
  ListItemText,
  CircularProgress,
} from "@mui/material";
// components
import { accessTokenForApi } from "../../../authentication/authConfig";
import { env } from "../../../env";
import BoardsDetailsPage from "./BoardsDetailsPage";
// ----------------------------------------------------------------------

const NAV_WIDTH = 360;

const BASE_URL = env.REACT_APP_BACKEND_BASE_URL;

//---------------------------------------------------------------------------

export default function DashboardsOverviewPage() {

  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [isTeamsListLoading, setIsTeamsListLoading] = useState(false);


  //-----------------------------------------------------------------------------------

  const handleTeamSelect = (event, teamname) => {
    const selectedIndex = selectedTeam.indexOf(teamname);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTeam, teamname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTeam.slice(1));
    } else if (selectedIndex === selectedTeam.length - 1) {
      newSelected = newSelected.concat(selectedTeam.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTeam.slice(0, selectedIndex),
        selectedTeam.slice(selectedIndex + 1)
      );
    }
    setSelectedTeam(newSelected);
  };

  //---------------------------------------------------------------------------------

  const fetchTeamsList = useCallback(async () => {
    setIsTeamsListLoading(true);
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(BASE_URL + "/team/getAllTeamWithboards", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (response.status === 404) {
        setIsTeamsListLoading(false);
      } else {
        const data = await response.json();
        const transformedList = data.resultSet.data.map((listData) => {
          return {
            id: listData.index,
            activedashboards: listData.active,
            teamname: listData.team_name,
            totaldashboards: listData.total,
          };
        });
        setTeamsList(transformedList);
        setIsTeamsListLoading(false);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchTeamsList();
  }, [fetchTeamsList]);

  //------------------------------------------------------------------------------------------

  return (
    <>
      <Helmet>
        <title> Dashboards | Dashboard Insights </title>
      </Helmet>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          sx={{
            width: NAV_WIDTH,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: NAV_WIDTH,
              boxSizing: "border-box",
            },
          }}
        >
          <Box sx={{ overflow: "auto" }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                paddingTop: 12,
                paddingLeft: 6,
                color: "#022F8E",
                fontSize: { xs: "none", md: 16, lg: 20, xl: 24 },
              }}
            >
              Teams
            </Typography>
            {isTeamsListLoading ? (
              <Box sx={{ flexGrow: 1, textAlign: "center", paddingTop: 10 }}>
                <CircularProgress />
              </Box>
            ) : (
              <List sx={{ paddingTop: 2, paddingLeft: 5 }}>
                {teamsList.map((team, index) => (
                  <ListItem key={team.id}>
                    <ListItemButton
                       selected={selectedTeam.indexOf(team.teamname) !== -1}
                      onClick={(event) =>
                        handleTeamSelect(event, team.teamname)
                      }
                    >
                      <ListItemText primary={team.teamname} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
            <Divider />
          </Box>
        </Drawer>
        <BoardsDetailsPage selectedTeam={selectedTeam} />
      </Box>
    </>
  );
}
