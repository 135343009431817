import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const InputTextField = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: props.width ? props.width : 275,
        "& > :not(style)": { m: 1 },
      }}
    >
      {/* {props.defaultValue && (
        <TextField
          id={props.id}
          label={props.label}
          size="small"
          defaultValue={props.defaultValue}
          inputProps={{ readOnly: true }}
         
        />
      )}
      {!props.defaultValue && (
        <TextField
          placeholder={props.placeholder}
          id={props.id}
          label={props.label}
          fullWidth
          size="small"
          helperText={props.helperText}
          onChange={props.onChange}
          error={props.error}
        />
      )} */}
      <TextField
          placeholder={props.placeholder}
          id={props.id}
          label={props.label}
          defaultValue={props.defaultValue}
          value={props.value}
          fullWidth
          rows={props.rows}
          size="small"
          onBlur={props.onBlur}
          helperText={props.helperText}
          onChange={props.onChange}
          error={props.error}
          multiline={props.multiline}
        />
    </Box>
  );
};

export default InputTextField;
