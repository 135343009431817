import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { AuthenticatedTemplate } from "@azure/msal-react";
//
//import Nav from './nav';
import MUIHeader from "../../components/UI/Header/MUIHeader";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 14;
const APP_BAR_DESKTOP = 12;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  return (
    <AuthenticatedTemplate>
      <StyledRoot>
        <MUIHeader />
        <Main>
          <Outlet />
        </Main>
      </StyledRoot>
    </AuthenticatedTemplate>
  );
}
