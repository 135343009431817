import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
//@Mui
import {
  Box,
  Link,
  Typography,
  Avatar,
  Divider,
  ListItemText,
  Drawer,
  Card,
  Grid,
  CssBaseline,
  ListItem,
  List,
  ListItemButton,
  Backdrop,
  Fade,
  Modal,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
//Components--------------------------------------------------------------
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import UrlListManager from "./urlsList/UrlListManager";
import DeviceDetailsCard from "./deviceDetailsCard/DeviceDetailsCard";
import navConfig from "./config";
import SuccessDialog from "../../UI/alerts/SuccessfulAdd";
import AddOrUpdateUrl from "./urlsList/AddOrUpdateUrl";
import dashboard from "../../data/Dashboard";
import DeviceContext from "./store/device-context";
import { accessTokenForApi } from "../../../authentication/authConfig";
import { addRecentActivity } from "../../activityTracker/activityUpdate";
import { env } from "../../../env";
import AlertDialog from "../../UI/alerts/AlertDialog";
//---------------------------------------------------------------
const NAV_WIDTH_LG = 200;
const NAV_WIDTH_XL = 300;
const BASE_URL = env.REACT_APP_BACKEND_BASE_URL;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

const style = {
  position: "absolute",
  top: { md: "10%", lg: "40%", xl: "50%" },
  left: { md: "10%", lg: "40%", xl: "50%" },
  transform: "translate(-50%, -50%)",
  width: { md: "70vw", lg: 650, xl: 850 },
  height: { md: "60vh", lg: 350, xl: 450 },
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 54,
  p: 4,
};

//--------------------------------------------------------------------------

export default function DeviceOverviewPage() {
  const { state } = useLocation();
  const { instance } = useMsal();

  const activeAccount = instance.getActiveAccount();
  const deviceDetailsConfig = [
    {
      title: "Client Owner",
      icon: "/assets/icons/owner_icon.png",
      value: state.rowData.owner,
    },

    {
      title: "Client Location",
      icon: "/assets/icons/office_icon.png",
      value:
        "Floor " +
        state.rowData.floor +
        "   -   " +
        state.rowData.location +
        ", " +
        state.rowData.country,
    },
    {
      title: "Board ID",
      icon: "/assets/icons/boards.png",
      value: state.rowData.id,
    },

    {
      title: "IP Address",
      icon: "/assets/icons/ip_icon.png",
      value: state.rowData.ipaddress,
    },
  ];

  //const [open, setOpen] = useState(null);
  const [isUpdateOperation, setIsUpdateOperation] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [restartDeviceError, setRestartDeviceError] = useState("");
  const [restartConfirm, SetRestartConfirm] = useState(false);
  const [restartStatus, setRestartStatus] = useState(false);
  const [openAddOrUpdateUrl, SetOpenAddOrUpdateUrl] = useState(false);
  const [urlAddOrUpdateStatus, setUrlAddOrUpdateStatus] = useState(false);

  const updateOperationHandler = () => {
    setIsUpdateOperation(true);
  };
  const restartStatusResetHandler = () => {
    setRestartStatus(false);
    setSelectedOption([]);
  };
  const handleOpenUrlAddOrUpdate = () => {
    SetOpenAddOrUpdateUrl(true);
  };
  const handleCloseUrlAddOrUpdate = () => {
    SetOpenAddOrUpdateUrl(false);
    // setOpen(null);
    setSelectedOption([]);
  };
  const handleOptionClick = (event, option) => {
    const selectedIndex = selectedOption.indexOf(option);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = option;
    }
    if (newSelected === "restart device") {
      SetRestartConfirm(true);
    } else if (newSelected === "add new url") {
      handleOpenUrlAddOrUpdate();
    }
    setSelectedOption(newSelected);
  };

  const urlAddOrUpdateStatusResetHandler = () => {
    setUrlAddOrUpdateStatus(false);
  };
  const urlAddOrUpdateStatusHandler = () => {
    setUrlAddOrUpdateStatus(true);
  };
  const openRestartUrlConfirmResetHandler = () => {
    SetRestartConfirm(false);
    setSelectedOption([]);
  };

  const restartDeviceHandler = async (boardId, boardName) => {
    const operation = {
      operations_performed: "Restarted",
      component_type: "dashboard",
      component_name: boardName,
      update_by: activeAccount.name,
    };
    setRestartDeviceError(null);
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(BASE_URL + `/board/rebootBoard/${boardId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      const data = await response.json();
      if (data.Status === "success") {
        openRestartUrlConfirmResetHandler(false);
        setRestartStatus(true);
        addRecentActivity(operation);
      }
    } catch (error) {
      setRestartDeviceError(error.message || "Something went wrong!");
      console.log(restartDeviceError);
    }
  };

  return (
    <>
      <Helmet>
        <title> Device | Dashboards </title>
      </Helmet>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: { md: NAV_WIDTH_LG, xl: NAV_WIDTH_XL },
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: { md: NAV_WIDTH_LG, xl: NAV_WIDTH_XL },
            boxSizing: "border-box",
          },
        }}
      >
        <Box sx={{ overflow: "auto", paddingTop: 10 }}>
          <Link underline="none">
            <StyledAccount>
              <Avatar src={dashboard.photoURL} alt="photoURL" />

              <Box sx={{ ml: 2 }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "text.primary",
                    fontSize: { md: 8, lg: 10, xl: 16 },
                  }}
                >
                  {state.rowData.boardname}
                </Typography>

                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {state.rowData.piuser}
                </Typography>
              </Box>
            </StyledAccount>
          </Link>

          <List sx={{ paddingTop: 2, paddingLeft: 0 }}>
            {navConfig.map((config, index) => (
              <ListItem key={config.title}>
                <ListItemButton
                  selected={selectedOption.indexOf(config.title) !== -1}
                  onClick={(event) => handleOptionClick(event, config.title)}
                >
                  <StyledNavItem
                    sx={{
                      "&.active": {
                        color: "text.primary",
                        bgcolor: "action.selected",
                        fontWeight: "fontWeightBold",
                      },
                    }}
                  >
                    <StyledNavItemIcon>
                      {config.icon && config.icon}
                    </StyledNavItemIcon>
                    <ListItemText primary={config.title} />
                  </StyledNavItem>
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Divider />
        </Box>
      </Drawer>
      {restartConfirm && (
          <AlertDialog
            open={restartConfirm}
            component="Device"
            name={state.rowData.boardname}
            operation="restart"
            onCancelClick={openRestartUrlConfirmResetHandler}
            onOkClick={() => restartDeviceHandler(state.rowData.id, state.rowData.boardname)}
          />
        )}
        
      {restartStatus && (
        <SuccessDialog
          open={restartStatus}
          component="Device"
          operation="restarted"
          onOkClick={restartStatusResetHandler}
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        sx={{ width: 2000 }}
        open={openAddOrUpdateUrl}
        closeAfterTransition
        onClose={handleCloseUrlAddOrUpdate}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAddOrUpdateUrl}>
          <Box sx={style}>
            <DeviceContext.Provider
              value={{
                boardId: state.rowData.id,
                boardName: state.rowData.boardname,
              }}
            >
              <AddOrUpdateUrl
                isUpdate={isUpdateOperation}
                onCancelClick={handleCloseUrlAddOrUpdate}
                onUpdateClick={urlAddOrUpdateStatusHandler}
              />
            </DeviceContext.Provider>
          </Box>
        </Fade>
      </Modal>
      {urlAddOrUpdateStatus && (
        <SuccessDialog
          open={urlAddOrUpdateStatus}
          component="Url"
          operation="added"
          onOkClick={urlAddOrUpdateStatusResetHandler}
        />
      )}

      <Grid
        container
        direction={"column"}
        spacing={3}
        sx={{
          paddingLeft: { md: "none", lg: 32, xl: 38 },
          paddingTop: { md: 5, lg: 5, xl: 7 },
        }}
      >
        <Grid item xs={12}>
          <Grid container direction="row"
          spacing={1}
          >
            <Grid item xs={9}>
              <Card
                sx={{
                  display: { xs: "none", md: "none", lg: "flex", xl: "flex" },
                  background: "transparent",
                  color: "#022F8E",
                  padding: { xs: 1, md: 2, lg: 1, xl: 1 },
                   height: { md: 200, lg: 300, xl: 350 },
                  borderRadius: 2,
                  boxShadow: 10,
                  mr: "auto",
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  sx={{
                    rowGap: { xs: "none", md: 2, xl: 1 },
                    alignItems: "flex-start",
                    padding: { xs: "none", md: 1, xl: 2 },
                  }}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      noWrap
                      sx={{
                        display: { xs: "none", md: "flex" },
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: { xs: "none", md: 16, lg: 16, xl: 20 },
                        letterSpacing: "0rem",
                        color: "text.primary",
                        textDecoration: "none",
                      }}
                    >
                      Device Overview
                    </Typography>
                  </Grid>
                  <Grid item>
                    <DeviceDetailsCard
                      owner={state.rowData.owner}
                      piuser={state.rowData.piuser}
                      lastupdated={state.rowData.lastupdatedat}
                      data={deviceDetailsConfig}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={3}>
            <Card
                sx={{
                  background: "transparent",
                  color: "#022F8E",
                  padding: { xs: 1, md: 2, xl: 1 },
                  borderRadius: 2,
                  height: { md: 200, lg: 300, xl: 350 },
                  boxShadow: 10,
                  ml: "auto",
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  sx={{
                    rowGap: { xs: "none", md: 2, xl: 4 },
                    display: "flex",
                    width: "auto",
                    alignItems: "flex-start",
                    padding: { xs: "none", md: 1, xl: 2 },
                  }}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      noWrap
                      sx={{
                        display: {
                          xs: "none",
                          md: "none",
                          lg: "flex",
                          xl: "flex",
                        },
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: { xs: "none", md: 16, lg: 20, xl: 22 },
                        letterSpacing: "0rem",
                        color: "text.primary",
                        textDecoration: "none",
                      }}
                    >
                      Screenshot
                    </Typography>
                  </Grid>
                  <Grid item>
                  {/* screenshot to be shown */}
                  </Grid>
                </Grid>
              </Card>

            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} >
          <DeviceContext.Provider
            value={{
              boardId: state.rowData.id,
              boardName: state.rowData.boardname,
            }}
          >
            <UrlListManager
              title="URLs List"
              onEditClickHandler={handleOpenUrlAddOrUpdate}
              operationHandler={updateOperationHandler}
              urlAddStatus={urlAddOrUpdateStatus}
            />
          </DeviceContext.Provider>
        </Grid>
        {/* <Grid item xs={12}>
          <Grid
            container
            spacing={{ md: 1, lg: 3, xl: 5 }}
            sx={{ paddingTop: 10 }}
          >
            <Grid item xs={11}>
              <Card
                sx={{
                  display: { xs: "none", md: "none", lg: "flex", xl: "flex" },
                  background: "transparent",
                  color: "#022F8E",
                  padding: { xs: 1, md: 2, lg: 1, xl: 1 },
                  borderRadius: 2,
                  boxShadow: 10,
                  mr: "auto",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    sx={{
                      rowGap: { xs: "none", md: 2, xl: 1 },
                      alignItems: "flex-start",
                      padding: { xs: "none", md: 1, xl: 2 },
                    }}
                  >
                    <Grid item>
                      <Typography
                        variant="h4"
                        noWrap
                        sx={{
                          display: { xs: "none", md: "flex" },
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          fontSize: { xs: "none", md: 16, lg: 16, xl: 20 },
                          letterSpacing: "0rem",
                          color: "text.primary",
                          textDecoration: "none",
                        }}
                      >
                        Device Overview
                      </Typography>
                    </Grid>
                    <Grid item>
                      <DeviceDetailsCard
                        owner={state.rowData.owner}
                        piuser={state.rowData.piuser}
                        lastupdated={state.rowData.lastupdatedat}
                        data={deviceDetailsConfig}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
            {/* <Grid item>
              <Card
                sx={{
                  background: "transparent",
                  color: "#022F8E",
                  padding: { xs: 1, md: 2, xl: 1 },
                  borderRadius: 2,
                  width: { md: "25vw", lg: "20vw", xl: "22vw" },
                  height: { md: 200, lg: 250, xl: 300 },
                  boxShadow: 10,
                  ml: "auto",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  sx={{
                    rowGap: { xs: "none", md: 2, xl: 4 },
                    display: "flex",
                    width: "auto",
                    alignItems: "flex-start",
                    padding: { xs: "none", md: 1, xl: 2 },
                  }}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      noWrap
                      sx={{
                        display: {
                          xs: "none",
                          md: "none",
                          lg: "flex",
                          xl: "flex",
                        },
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: { xs: "none", md: 16, lg: 20, xl: 22 },
                        letterSpacing: "0rem",
                        color: "text.primary",
                        textDecoration: "none",
                      }}
                    >
                      Screenshot
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>  screenshot removal code till here 
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} lg={12} xl={11}>
          <DeviceContext.Provider
            value={{
              boardId: state.rowData.id,
              boardName: state.rowData.boardname,
            }}
          >
            <UrlListManager
              title="URLs List"
              onEditClickHandler={handleOpenUrlAddOrUpdate}
              operationHandler={updateOperationHandler}
              urlAddStatus={urlAddOrUpdateStatus}
            />
          </DeviceContext.Provider>
        </Grid> */}
      </Grid>
    </>
  );
}
