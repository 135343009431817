

// ----------------------------------------------------------------------

const CountryAndRegionList = {
  countries:[
    { value: "Denmark", text: "Denmark" },
    { value: "Finland", text: "Finland" },
    { value: "Lithuania", text: "Lithuania" },
    { value: "Norway", text: "Norway" },],

  regions: {
  Denmark: [
    { value: "Aarhus", text: "Aarhus" },
    { value: "Copenhagen", text: "Copenhagen" },
  ],
  Finland: [{ value: "Helsinki", text: "Helsinki" }],
  Lithuania: [{ value: "Vilnius", text: "Vilnius" }],
  Norway: [
    { value: "Oslo", text: "Oslo" },
  ],
  }
};

export default CountryAndRegionList ;
