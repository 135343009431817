import { createBrowserRouter } from "react-router-dom";
// layouts
import HomePage from "./components/pages/homepage/HomePage";
import UserLoggedInLandingPage from "./components/pages/loggedInHomepage/UserLoggedInLandingPage";
import TeamsOverviewPage from "./components/pages/teamsPage/TeamsOverviewPage";
import DashboardsOverviewPage from "./components/pages/dashboardpage/DashboardsOverviewPage";
import OfficePage from "./components/pages/offices/OfficesPage";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import DeviceOverviewPage from "./components/pages/deviceOverviewPage/DeviceOverviewPage";
import OfficeOverviewPage from "./components/pages/offices/OfficeOverviewPage";
//import { loader as countLoader } from "./components/pages/loggedInHomepage/UserLoggedInLandingPage";
// ----------------------------------------------------------------------

const routes = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "home",
        element: <UserLoggedInLandingPage />,
        index: true,
        /* loader: countLoader, */
      },
      {
        path: "teams",
        element: <TeamsOverviewPage />,
      },
      { path: "offices", element: <OfficeOverviewPage /> },
      { path: "officedetails", element: <OfficePage /> },
      { path: "dashboards", element: <DashboardsOverviewPage /> },
      {
        path: "deviceoverview",
        element: <DeviceOverviewPage />,
      },
    ],
  },

  /*   {
    element: <SimpleLayout />,
    children: [
      { element: <Navigate to="/dashboard/app" />, index: true },
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  }, */
  /*   {
    path: '*',
    element: <Navigate to="/404" replace />,
  }, */
]);

export default routes;
