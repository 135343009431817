import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
// @Mui
import {
  CssBaseline,
  Typography,
  Grid,
  Stack,
  Box,
  Button,
} from "@mui/material";
//components
import InputTextField from "../../UI/FormInput/InputTextField";
import Iconify from "../../iconify/Iconify";
import { env } from "../../../env";
//datas
import { accessTokenForApi } from "../../../authentication/authConfig";
import { addRecentActivity } from "../../activityTracker/activityUpdate";
//-----------------------------------------------------------------------------------------

//--------------------------------------------------------------------------

const BASE_URL = env.REACT_APP_BACKEND_BASE_URL;

//---------------------------------------------------------------------------

const AddFloor = (props) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  //---------------------------------------------------------------

  const [floor, setFloor] = useState("");
  const [floorError, setFloorError] = useState(false);
  const [addFloorError, setAddFloorError] = useState(null);
  const [floorErrorMsg, setFloorErrorMsg] = useState("");
  //const [divData, setDivData] = useState([]);
  const [floorAlreadyExist, setFloorAlreadyExist] = useState(false);

  let formIsValid = false;

  //-----------------------------------------------------

  if (floor && !floorError) {
    formIsValid = true;
  }

  const handleFloorChange = (event) => {
    if (event.target.value && !event.target.value.match(/^[0-9]+$/)) {
      setFloorError(true);
      setFloorErrorMsg("Oh oh....sorry!!! only one floor at a time");
    } else {
      setFloorError(false);
      setFloorErrorMsg("");
    }
    setFloor(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setFloorError(false);

    if (floor === "") {
      setFloorErrorMsg("Invalid floor");
      setFloorError(true);
    }
    if (floor && !floorError) {
      formIsValid = true;
      const floorDetails = {
        office_id: props.officeId,
        office_floor: floor,
        updated_by: activeAccount.username,
      };
      const operation = {
        operations_performed: "Added",
        component_type: "floor",
        component_name: "in office - "+props.officeName,
        update_by: activeAccount.name,
      };
     
     addFloorHandler(floorDetails, operation);
    }
  };

  const addFloorHandler = async (floor, operation) => {
    setAddFloorError(null);
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(BASE_URL + "/office/addOfficefloor", {
        method: "POST",
        body: JSON.stringify(floor),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      const data = await response.json();
      if (data.Status === "success") {
        props.onCancelClick();
        props.onAddClick();
        addRecentActivity(operation);
      } else if (data.message.includes("already exist")) {
        setFloorAlreadyExist(true);
      }
    } catch (error) {
      setAddFloorError(error.message || "Something went wrong!");
      console.log(addFloorError);
    }
  };

  return (
    <>
      <Helmet>
        <title> Add Floor | Office </title>
      </Helmet>
      <CssBaseline />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            variant="h4"
            noWrap
            sx={{
              display: { xs: "none", md: "flex" },
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: { xs: "none", md: 16, lg: 20, xl: 22 },
              letterSpacing: "0rem",
              color: "text.primary",
              textDecoration: "none",
            }}
          >
            Add new Floor
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            sx={{
              display: "flex",
              columnGap: { xs: "none", md: 0, lg: 3, xl: 7 },
            }}
          >
            <Grid
              item
              sx={{ marginTop: { sm: "none", md: "none", lg: "none", xl: 1 } }}
            >
              <Box sx={{ width: "30vw" }}>
                <form autoComplete="off" onSubmit={submitHandler}>
                  <Stack direction="row">
                    <InputTextField
                      placeholder="Add floor number"
                      id="floor"
                      label="Floor"
                      helperText={
                        floorError ? floorErrorMsg : "Add floor number"
                      }
                      onChange={(e) => handleFloorChange(e)}
                      error={floorError}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    ml={1}
                    sx={{ mt: { xs: "none", md: "none", lg: "none", xl: 2 } }}
                    spacing={10}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      disabled={!formIsValid}
                    >
                      Add
                    </Button>
                    <Button variant="contained" onClick={props.onCancelClick}>
                      Cancel
                    </Button>
                  </Stack>
                </form>
              </Box>
              {floorAlreadyExist && (
                <Typography
                  variant="caption"
                  pt={5}
                  gutterBottom
                  sx={{
                    color: "#FF0000",
                    //fontSize: { xs: "none", md: 16, lg: 22, xl: 22 },
                  }}
                >
                  Floor already exist. Please try with a unique name
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddFloor;
