import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  FormGroup,
  CssBaseline,
  FormControlLabel,
  styled,
  Switch,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
//components
import InputTextField from "../../UI/FormInput/InputTextField";
import Iconify from "../../iconify/Iconify";
import { accessTokenForApi } from "../../../authentication/authConfig";
import { addRecentActivity } from "../../activityTracker/activityUpdate";
import { env } from "../../../env";
//const---------------------------------------------------------------------------

const BASE_URL = env.REACT_APP_BACKEND_BASE_URL;

const StatusSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

//------------------------------------------------------------------------------------

const UpdateTeam = (props) => {

  const theme = useTheme();

  const [checked, setChecked] = useState(
    props.datas.status === "inactive" ? false : true
  );

  const [teamName, setTeamName] = useState(props.datas.teamname);

  const [teamOwner, setTeamOwner] = useState(props.datas.teamowner);

  const [teamNameError, setTeamNameError] = useState(false);

  const [teamOwnerError, setTeamOwnerError] = useState(false);

  const [updateTeamError, setUpdateTeamError] = useState(null);

  const [teamAlreadyExist, setTeamAlreadyExist] = useState(false);

  const [teamNameErrorMsg, setTeamNameErrorMsg] = useState("");

  const [teamOwnerErrorMsg, setTeamOwnerErrorMsg] = useState("");

  let formIsValid = false;

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  if (teamName && teamOwner && !teamNameError && !teamOwnerError) {
    formIsValid = true;
  }
  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
  const handleTeamNameChange = (event) => {
    setTeamAlreadyExist(false);
    if (event.target.value && !event.target.value.match(/^[a-zA-Z ]+$/)) {
      setTeamNameError(true);
      setTeamNameErrorMsg("Only letters allowed");
    } else {
      setTeamNameError(false);
      setTeamNameErrorMsg("");
    }
    setTeamName(event.target.value);
  };

  const handleTeamOwnerChange = (event) => {
    if (event.target.value && !event.target.value.match(/^[a-zA-Z ]+$/)) {
      setTeamOwnerError(true);
      setTeamOwnerErrorMsg("Only letters allowed");
    } else {
      setTeamOwnerError(false);
      setTeamOwnerErrorMsg("");
    }
    setTeamOwner(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setTeamNameError(false);
    setTeamOwnerError(false);
    if (teamName === "") {
      setTeamNameError(true);
    }
    if (teamOwner === "") {
      setTeamOwnerError(true);
    }

    if (teamName && teamOwner && !teamNameError && !teamOwnerError) {
      formIsValid = true;
      const team = {
        team_id: props.datas.id,
        team_name: teamName,
        team_owner: teamOwner,
        active: checked,
      };
      const operation = {
        operations_performed: "Updated",
        component_type: "team",
        component_name: teamName,
        update_by: activeAccount.name,
      };
      updateTeamsHandler(team, operation);
    }
  };

  const updateTeamsHandler = async (team, operation) => {
    setUpdateTeamError(null);
    formIsValid = true;
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(BASE_URL + "/team/UpdateTeamStatus", {
        method: "PUT",
        body: JSON.stringify(team),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      const data = await response.json();
      if (data.Status === "success") {
        props.onCancelClick();
        props.onUpdateClick();
        addRecentActivity(operation);
      } else if (data.message.includes("Internal ")) {
        setTeamAlreadyExist(true);
      }
    } catch (error) {
      setUpdateTeamError(error.message || "Something went wrong!");
      console.log(updateTeamError);
    }
  };

  return (
    <>
      <Helmet>
        <title> Update Team | Teams </title>
      </Helmet>
      <CssBaseline />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            variant="h4"
            noWrap
            sx={{
              display: { xs: "none", md: "flex", lg: "flex", xl: "flex" },
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: { xs: "none", md: 16, lg: 20, xl: 22 },
              letterSpacing: "0rem",
              color: "text.primary",
              textDecoration: "none",
            }}
          >
            Update team
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            sx={{
              columnGap: { xs: "none", md: 0, lg: 3, xl: 5 },
            }}
          >
            <Grid item sx={{ marginTop: { xl: 2 } }}>
              <Box sx={{ width: 400 }}>
                <Stack direction="row">
                  <InputTextField
                    placeholder="Enter team name"
                    id="teamname"
                    label="Team Name"
                    helperText={
                      teamNameError ? teamNameErrorMsg : "Name of the team"
                    }
                    defaultValue={teamName ? teamName : ""}
                    onChange={(e) => handleTeamNameChange(e)}
                    error={teamNameError}
                  />
                  <InputTextField
                    placeholder="Enter owner name"
                    id="owner"
                    label="Owner Name"
                    defaultValue={teamOwner ? teamOwner : ""}
                    helperText={
                      teamOwnerError ? teamOwnerErrorMsg : "Team leader name"
                    }
                    onChange={(e) => handleTeamOwnerChange(e)}
                    error={teamOwnerError}
                  />
                </Stack>
                <Stack direction="row" mt={1} spacing={2}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StatusSwitch
                          sx={{ m: 2 }}
                          checked={checked}
                          onChange={toggleChecked}
                        />
                      }
                      label={`${checked ? "Active" : "Inactive"}`}
                    />
                  </FormGroup>
                </Stack>
                <Stack direction="row" ml={1} mt={3} spacing={10}>
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={submitHandler}
                    disabled={!formIsValid}
                  >
                    Update
                  </Button>
                  <Button variant="contained" onClick={props.onCancelClick}>
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </Grid>

            <Grid item mt={1}>
              <Box
                component="img"
                alt="dashboardIcon"
                src="/assets/images/illustrators/teams_illustration.png"
                sx={{
                  [theme.breakpoints.up("sm")]: {
                    display: "none",
                  },
                  [theme.breakpoints.up("md")]: {
                    display: "none",
                  },

                  [theme.breakpoints.up("lg")]: {
                    display: "none",
                    width: "27vw",
                    height: "25vh",
                  },

                  [theme.breakpoints.up("xl")]: {
                    display: "flex",
                    width: 280,
                    height: 200,
                  },

                  flexShrink: 1,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {teamAlreadyExist && (
          <Typography
            variant="caption"
            pt={5}
            gutterBottom
            sx={{
              color: "#FF0000",
              //fontSize: { xs: "none", md: 16, lg: 22, xl: 22 },
            }}
          >
            Team already exist. Please try with a unique name
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default UpdateTeam;
