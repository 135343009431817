import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMsal } from "@azure/msal-react";
import { accessTokenForApi } from "../../../authentication/authConfig";
import {
  Box,
  Stack,
  Grid,
  CssBaseline,
  Typography,
  FormGroup,
  FormControlLabel,
  styled,
  Switch,
  Button,
  CircularProgress,
} from "@mui/material";
//import { PeoplePicker } from '@microsoft/mgt-react';
//Components
import InputTextField from "../../UI/FormInput/InputTextField";
import InputSelectList from "../../UI/FormInput/InputSelectList";
import Iconify from "../../iconify/Iconify";
import { addRecentActivity } from "../../activityTracker/activityUpdate";
import { env } from "../../../env";
// Constants -------------------------------------------------------------

const BASE_URL = env.REACT_APP_BACKEND_BASE_URL;

const StatusSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

//---------------------------------------------------------------------------------------------

const AddDashboard = (props) => {

  let isFirstload = true;
  let checkedStatus = true;
  let formIsValid = false;
  const theme = useTheme();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  if (props.datas.status === "inactive") {
    checkedStatus = false;
  }

  const [checked, setChecked] = useState(checkedStatus);

  const [floorListValues, setFloorListValues] = useState([]);

  const [boardName, setBoardName] = useState(props.datas.boardname);

  const [ownerName, setOwnerName] = useState(props.datas.owner);

  const [piuser, setpiuser] = useState(props.datas.piuser);

  const [officeList, setOfficeList] = useState([]);

  const [fetchOfficeListError, setFetchOfficeListError] = useState(null);

  const [teamsList, setTeamsList] = useState([]);

  const [fetchTeamsListError, setFetchTeamsListError] = useState(null);

  const [selectedOfficeFloorListError, setSelectedOfficeFloorListError] =
    useState("");

  const [boardNameError, setBoardNameError] = useState(false);

  const [officeNameError, setOfficeNameError] = useState(false);

  const [ownerNameError, setOwnerNameError] = useState(false);

  const [floorError, setFloorError] = useState(false);

  const [updateDashboardError, setUpdateDashboardError] = useState(null);

  const [teamError, setTeamError] = useState(false);

  const [piUserError, setpiUserError] = useState(false);

  //const [currentBoardDetails, setCurrentBoardDetails] = useState([]);

  //const [fetchCurrentBoardDetailsError, setFetchCurrentBoardDetailsError] = useState(null);

  const [officeName, setofficeName] = useState("");

  const [floor, setFloor] = useState("");

  const [team, setTeam] = useState("");

  const [boardAlreadyExist, setBoardAlreadyExist] = useState(false);

  const [boardNameErrorMsg, setBoardNameErrorMsg] = useState("");

  const [ownerNameErrorMsg, setOwnerNameErrorMsg] = useState("");

  const [piUserErrorMsg, setPiUserErrorMsgMsg] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  //----------------------------------------------------------------------------------------

  const isUpdating = navigate.state === "submitting";

  const currrentBoardId = props.datas.id;

  if (
    ownerName &&
    piuser &&
    boardName &&
    officeName &&
    team &&
    floor &&
    !ownerNameError &&
    !piUserError &&
    !boardNameError &&
    !officeNameError &&
    !teamError &&
    !floorError
  ) {
    formIsValid = true;
  }

  const fetchSelectedOfficeFloorList = useCallback(
    async (officeId) => {
      let officeFloors = [];
      if (officeId) {
        try {
          const authenticatedValue = await accessTokenForApi();
          const response = await fetch(
            BASE_URL + `/office/getOfficefloor/${officeId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authenticatedValue.accessToken,
              },
            }
          );
          if (!response.ok) {
            throw new Error("Something went wrong");
          }
          const data = await response.json();
          const transformedList = data.resultSet.data.map((listData) => {
            return {
              officefloor: listData.office_floor,
            };
          });
          transformedList[0].officefloor.forEach((floor) => {
            officeFloors.push({
              text: floor,
              value: floor,
            });
          });
          setFloorListValues(officeFloors);
          isFirstload && setFloor(props.datas.floor);

        } catch (error) {
          setSelectedOfficeFloorListError(error.message);
          console.log(selectedOfficeFloorListError);
        }
      }
    },
    // eslint-disable-next-line
    [selectedOfficeFloorListError, props.datas.floor, isFirstload]
  );

  const fetchOfficeList = useCallback(async () => {
    setFetchOfficeListError(null);
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(BASE_URL + "/office/getAllOffice", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      const transformedList = data.resultSet.data.map((listData) => {
        return {
          value: listData.office_id,
          text: listData.office_name,
          floors: listData.office_floor,
        };
      });
      transformedList.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });

      setOfficeList(transformedList);

      !officeName && setofficeName(props.datas.officeid);

      fetchSelectedOfficeFloorList(props.datas.officeid);
    } catch (error) {
      setFetchOfficeListError(error.message);
      console.log(fetchOfficeListError);
    }
    // eslint-disable-next-line
  }, [fetchOfficeListError, props.datas.officeid]);

  const fetchTeamsList = useCallback(async () => {
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(BASE_URL + "/team/getAllTeams", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      const transformedList = data.resultSet.map((listData) => {
        return {
          value: listData.team_id,
          text: listData.team_name,
        };
      });
      setTeamsList(transformedList);
      setTeam(props.datas.teamid);
    } catch (error) {
      setFetchTeamsListError(error.message);
      console.log(fetchTeamsListError);
    }
  }, [fetchTeamsListError, props.datas.teamid]);

  /* const fetchCurrentBoardDetails = useCallback(async () => {
    setFetchCurrentBoardDetailsError(null);
    
    try {
      const response = await fetch(BASE_URL + `/board/getBoard/${currrentBoardId}`);
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      
       const transformedList = data.resultSet.data.map((listData) => {
        return {
          boardname: listData.board_name,
          owner: listData.board_owner,
          officename: listData.office_name,
          piuser: listData.pi_user,
          teamid: listData.team_id,
        };
      });
      transformedList.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      setCurrentBoardDetails(transformedList); 
      currentBoardDetails.map((board) => (
      setBoardName(board.boardname)
      ))
      console.log("Boardname: "+boardName)
    } catch (error) {
      setFetchCurrentBoardDetailsError(error.message);
      console.log(fetchCurrentBoardDetailsError); 
    }
  }, [fetchCurrentBoardDetailsError]); */

  const fetchDataForForm = useCallback(async () => {
    setIsLoading(true);
    const promise1 = fetchOfficeList();
    const promise2 = fetchTeamsList();
    Promise.all([promise1, promise2]).then((values) => {
      setIsLoading(false);
    });
  }, [fetchOfficeList, fetchTeamsList]);

  useEffect(() => {
    fetchDataForForm();
    //fetchOfficeList();
    //fetchTeamsList();
    // fetchCurrentBoardDetails();
  }, [fetchDataForForm]);

  const submitHandler = (event) => {
    event.preventDefault();
    isFirstload = true;

    setOfficeNameError(false);
    setFloorError(false);
    setTeamError(false);

    if (officeName === "") {
      setOfficeNameError(true);
    }
    if (floor === "") {
      setFloorError(true);
    }
    if (team === "") {
      setTeamError(true);
    }
    /*  if (
      (ownerName ownerNameIsValid) &&
      piuser &&
      officeName &&
      floor &&
      team
    ) */ {
      const board = {
        board_id: currrentBoardId,
        board_name: boardName,
        board_owner: ownerName,
        pi_user: piuser,
        board_floor: floor,
        office_id: officeName,
        team_id: team,
        updated_by: activeAccount ? activeAccount.username : "",
        active: checked,
      };
      formIsValid = true;
      updateDasboardHandler(board);
    }
  };

  const updateDasboardHandler = async (dashboard) => {
    const operation = {
      operations_performed: "Updated",
      component_type: "dashboard",
      component_name: dashboard.board_name,
      update_by: activeAccount.name,
    };
    setUpdateDashboardError(null);
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(BASE_URL + "/board/updateBoard", {
        method: "PUT",
        body: JSON.stringify(dashboard),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      const data = await response.json();
      if (data.Status === "success") {
        props.onCancelClick();
        props.onUpdateClick();
        addRecentActivity(operation);
      }
    } catch (error) {
      setUpdateDashboardError(error.message || "Something went wrong!");
      console.log(updateDashboardError);
    }
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
  const handleChange = (event) => {
    setFloor("");
    isFirstload = false;
    setofficeName(event.target.value);
    fetchSelectedOfficeFloorList(event.target.value);
  };
  const handleFloorChange = (event) => {
    setFloor(event.target.value);
  };
  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };
  const boardNameChangeHandler = (event) => {
    if (boardAlreadyExist) {
      setBoardAlreadyExist(false);
    }
    if (event.target.value && !event.target.value.match(/^[a-zA-Z0-9- ]+$/)) {
      setBoardNameError(true);
      setBoardNameErrorMsg("Only letters & numbers are allowed");
    } else {
      setBoardNameError(false);
      setBoardNameErrorMsg("");
    }
    setBoardName(event.target.value);
  };
  const ownerNameChangeHandler = (event) => {
    if (event.target.value && !event.target.value.match(/^[a-zA-Z ]+$/)) {
      setOwnerNameError(true);
      setOwnerNameErrorMsg("Only letters are allowed");
    } else {
      setOwnerNameError(false);
      setOwnerNameErrorMsg("");
    }
    setOwnerName(event.target.value);
  };
  const piuserChangeHandler = (event) => {
    if (event.target.value && !event.target.value.match(/^[a-zA-Z0-9@. ]+$/)) {
      setpiUserError(true);
      setPiUserErrorMsgMsg("Only letters & numbers are allowed");
    } else {
      setpiUserError(false);
      setPiUserErrorMsgMsg("");
    }
    setpiuser(event.target.value);
  };
  const handleFormCancelClick = () => {
    isFirstload = true;
    props.onCancelClick();
  };

  return (
    <>
      <Helmet>
        <title> Add Board | Dashboards </title>
      </Helmet>
      <CssBaseline />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            variant="h4"
            noWrap
            sx={{
              display: { xs: "none", md: "flex" },
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: { xs: "none", md: 16, lg: 20, xl: 22 },
              letterSpacing: "0rem",
              color: "text.primary",
              textDecoration: "none",
            }}
          >
            Add new board
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            sx={{
              columnGap: { xs: "none", md: 0, lg: 3, xl: 3 },
            }}
          >
            <Grid
              item
              sx={{ marginTop: { sm: "none", md: "none", lg: "none", xl: 1 } }}
            >
              <Box sx={{ width: 400 }}>
                {isLoading ? (
                  <Box sx={{ flexGrow: 1, textAlign: "center", paddingTop: 7 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form autoComplete="off" onSubmit={submitHandler}>
                    <Stack direction="row">
                      <InputTextField
                        placeholder="Enter board name"
                        id="boardname"
                        label="Board Name"
                        helperText={
                          boardNameError ? boardNameErrorMsg : "Name of device"
                        }
                        defaultValue={boardName ? boardName : ""}
                        onChange={boardNameChangeHandler}
                        //onBlur={boardNameBlurHandler}
                        error={boardNameError}
                      />
                      <InputTextField
                        placeholder="Enter owner name"
                        id="owner"
                        label="Owner Name"
                        helperText={
                          ownerNameError
                            ? ownerNameErrorMsg
                            : "Owner of the board"
                        }
                        defaultValue={ownerName ? ownerName : ""}
                        onChange={ownerNameChangeHandler}
                        // onBlur={ownerNameBlurHandler}
                        error={ownerNameError}
                      />
                    </Stack>
                    <Stack direction="row">
                      <InputTextField
                        placeholder="Enter pi username"
                        id="piuser"
                        label="Pi Username"
                        helperText={
                          piUserError ? piUserErrorMsg : "Username of Pi"
                        }
                        defaultValue={piuser ? piuser : ""}
                        onChange={piuserChangeHandler}
                        //onBlur={piuserBlurHandler}
                        error={piUserError}
                      />
                      <InputSelectList
                        name="officeName"
                        value={officeName ? officeName : ""}
                        label="Office"
                        onChange={handleChange}
                        values={officeList}
                        helperText="Name of the office"
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                        error={officeNameError}
                      />
                    </Stack>
                    <Stack direction="row">
                      <InputSelectList
                        name="floor"
                        label="Floor"
                        value={floor ? floor : ""}
                        onChange={handleFloorChange}
                        values={floorListValues}
                        helperText="Floor at which office is located"
                        error={floorError}
                      />
                      <InputSelectList
                        name="teamname"
                        label="Team Name"
                        value={team ? team : ""}
                        onChange={handleTeamChange}
                        values={teamsList}
                        helperText="Team owned by this dashboard"
                        error={teamError}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <StatusSwitch
                              sx={{ m: 2 }}
                              checked={checked}
                              onChange={toggleChecked}
                            />
                          }
                          label={`${checked ? "ON" : "OFF"}`}
                        />
                      </FormGroup>
                    </Stack>
                    <Stack direction="row" ml={1} mt={1} spacing={10}>
                      <Button
                        type="submit"
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        disabled={!formIsValid || isUpdating}
                      >
                        {isUpdating ? "Updating..." : "Update"}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleFormCancelClick}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </form>
                )}
              </Box>
              {boardAlreadyExist && (
                <Typography
                  variant="caption"
                  pt={5}
                  gutterBottom
                  sx={{
                    color: "#FF0000",
                    //fontSize: { xs: "none", md: 16, lg: 22, xl: 22 },
                  }}
                >
                  Board already exist. Please try with a unique name
                </Typography>
              )}
            </Grid>

            <Grid item ml={1}>
              <Box
                component="img"
                alt="dashboardIcon"
                src="/assets/images/illustrators/add_dashboard.jpg"
                sx={{
                  [theme.breakpoints.up("sm")]: {
                    display: "none",
                  },
                  [theme.breakpoints.up("md")]: {
                    display: "none",
                  },
                  [theme.breakpoints.up("lg")]: {
                    display: "none",
                    width: "20vw",
                    height: "33vh",
                  },
                  [theme.breakpoints.up("xl")]: {
                    display: "flex",
                    width: 300,
                    height: 250,
                  },
                  flexShrink: 1,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddDashboard;
