import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from '@mui/material/FormHelperText';
const InputSelectList = (props) => {
  const inputProps = {
    placeholder: 'Please select...'
};

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: 275,
        "& > :not(style)": { m: 1 },
      }}
    >
      <FormControl fullWidth>
        <InputLabel id={props.name + "-label"}>{props.label}</InputLabel>
        <Select
          labelId={props.name + "-label"}
          id={props.name + "-select"}
          inputProps={inputProps}
          disabled={props.disabled}
          value={props.value}
          label={props.label}
          onChange={props.onChange}
          options={props.options}
          size="small"
          error={props.error}
          MenuProps={props.MenuProps}
        >
          {props.values.map((value) => (
            <MenuItem key={value.value} value={value.value}>{value.text}</MenuItem>
          ))}
          {/* <MenuItem value={"Vipps AS"}>Vipps AS</MenuItem>
          <MenuItem value={"DNB Kundesenter"}>DNB Kundesenter</MenuItem>
          <MenuItem value={"MobilePay"}>MobilePay</MenuItem> */}
        </Select>
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default InputSelectList;
