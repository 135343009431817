import { Helmet } from "react-helmet-async";
import { sentenceCase } from "change-case";
import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

// @mui
import {
  Typography,
  Drawer,
  CssBaseline,
  Divider,
  ListItemButton,
  Box,
  //Button,
  List,
  ListItemText,
  Collapse,
  CircularProgress,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
// components
import { env } from "../../../env";
import BoardsDetailsPage from "../dashboardpage/BoardsDetailsPage";
//context
import OfficeContext from "./store/office-context";
//auth
import { accessTokenForApi } from "../../../authentication/authConfig";


// Global constants------------------------------------------------------

const BASE_URL = env.REACT_APP_BACKEND_BASE_URL;

const NAV_WIDTH = 350;

// ----------------------------------------------------------------------
export default function OfficePage() {
  const { state } = useLocation();
  const [officeList, setOfficeList] = useState([]);
  const [fetchOfficeListError, setFetchOfficeListError] = useState(null);
  const [openList, setOpenList] = useState({});
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [isLocLoading, setIsLocLoading] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState("");

  //----------------------------------------------------------------------------------

  const setMenuOpenList = (id) => {
    setOpenList({[id]: true});
  }
  const setSelectedOfficeName = (officename) => {
    setSelectedOffice(officename);
  }

  const handleExpandListClick = (id) => {
    setOpenList((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleLocationSelect = (location) => {
    setSelectedOffice("");
    const selectedIndex = selectedLocation.indexOf(location);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedLocation, location);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedLocation.slice(1));
    } else if (selectedIndex === selectedLocation.length - 1) {
      newSelected = newSelected.concat(selectedLocation.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedLocation.slice(0, selectedIndex),
        selectedLocation.slice(selectedIndex + 1)
      );
    }
    setSelectedLocation(newSelected);
  };
  /*  const handleClick = (event, boardname) => {
    const selectedIndex = selected.indexOf(boardname);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, boardname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  }; */

  // Api calls ------------------------------------------------------------

  const fetchOfficeList = useCallback(async () => {
    setIsLocLoading(true);
    setFetchOfficeListError(null);
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(BASE_URL + "/office/getListOffice", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      const transformedList = data.resultSet.data.map((listData) => {
        return {
          country: listData.office_country,
          location: listData.office_location,
        };
      });
      transformedList.sort(function (a, b) {
        if (a.country < b.country) {
          return -1;
        }
        if (a.country > b.country) {
          return 1;
        }
        return 0;
      });
      setOfficeList(transformedList);
      setIsLocLoading(false);
    } catch (error) {
      setFetchOfficeListError(error.message);
      setIsLocLoading(false);
      console.log(fetchOfficeListError);
    }
  }, [fetchOfficeListError]);

  //----------------------------------------------------------

  useEffect(() => {
    fetchOfficeList();
    handleLocationSelect(state.rowData.location);
    setMenuOpenList(state.rowData.country);
    setSelectedOfficeName(state.rowData.officename)
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title> Offices | Boards </title>
      </Helmet>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          sx={{
            width: NAV_WIDTH,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: NAV_WIDTH,
              boxSizing: "border-box",
            },
          }}
        >
          <Box sx={{ overflow: "auto" }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                paddingTop: 12,
                paddingLeft: 6,
                color: "#022F8E",
                fontSize: { xs: "none", md: 16, lg: 22, xl: 22 },
              }}
            >
              Locations
            </Typography>
            {isLocLoading ? (
              <Box sx={{ flexGrow: 1, textAlign: "center", paddingTop: 10 }}>
                <CircularProgress />
              </Box>
            ) : (
              <List sx={{ paddingTop: 2, paddingLeft: 5 }}>
                {officeList.map((office, index) => (
                  <List key={office.country} disablePadding>
                    <ListItemButton
                      onClick={() => handleExpandListClick(office.country)}
                    >
                      <ListItemText primary={sentenceCase(office.country)} />
                      {openList[office.country] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemButton>
                    <Collapse
                      in={openList[office.country]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {office.location.map((location, index) => (
                        <List component="div" key={index} disablePadding>
                          <ListItemButton
                            selected={selectedLocation.indexOf(location) !== -1}
                            onClick={() => handleLocationSelect(location)}
                          >
                            <ListItemText primary={location} />
                          </ListItemButton>
                        </List>
                      ))}
                    </Collapse>
                  </List>
                ))}
              </List>
            )}
            <Divider />
          </Box>
        </Drawer>

        {/*-------------------------------------------------------------------------------------- */}
        <OfficeContext.Provider
              value={{
                officeId: state.rowData.id,
                officeName: selectedOffice,
                selectedLocation: selectedLocation,
                isRedirectFromOffice: true
              }}
            >           
        <BoardsDetailsPage />
        </OfficeContext.Provider>
      </Box>
    </>
  );
}
