import { RouterProvider } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import ThemeProvider from "./theme";
import routes from "./routes";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import { StyledChart } from "./components/chart";

const App = ({ instance }) => {
  return (
   /*  <Helmet>
      <meta
        httpEquiv="Content-Security-Policy"
        content={`
                  script-src 'strict-dynamic' 'nonce-rAnd0m123' 'unsafe-inline';
            `}
      ></meta> */

      <MsalProvider instance={instance}>
        <ThemeProvider>
          <RouterProvider router={routes}>
            <ScrollToTop />
            <StyledChart />
          </RouterProvider>
        </ThemeProvider>
      </MsalProvider>
    /* </Helmet> */
  );
};
export default App;
