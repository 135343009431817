import { useState, useCallback, useContext } from "react";
import { useMsal } from "@azure/msal-react";
// @mui
import { Box, Stack, Fade, Modal, Backdrop, Typography } from "@mui/material";
// components
import AddDashboard from "./AddDashboard";
import SuccessDialog from "../../UI/alerts/SuccessfulAdd";
import AlertDialog from "../../UI/alerts/AlertDialog";
import { accessTokenForApi } from "../../../authentication/authConfig";
import { addRecentActivity } from "../../activityTracker/activityUpdate";
import { env } from "../../../env";
import PopOverMenu from "../../UI/PopOverMenu/PopOverMenu";
import ScreensInUse from "./ScreensInUse";
import RecentlyAddedScreens from "./RecentlyAddedScreens";
import OfficeContext from "../offices/store/office-context";

const BASE_URL = env.REACT_APP_BACKEND_BASE_URL;

export default function BoardsDetailsPage(props) {
  const ctx = useContext(OfficeContext);
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const [open, setOpen] = useState(null);
  const [openDashboardUpdate, SetOpenDashboardUpdate] = useState(false);
  const [dataInRow, setDataInRow] = useState([]);
  const [boardUpdateStatus, setBoardUpdateStatus] = useState(false);
  const [reFetchData, setReFetchData] = useState(false);
  const [openDeleteDashboardConfirm, SetOpenDeleteDashboardConfirm] =
    useState(false);
  const [dashboardDeleteStatus, setDashboardDeleteStatus] = useState(false);
  const [reFetchNewDevicesData, setReFetchNewDevicesData] = useState(false);

  const style = {
    position: "absolute",
    top: { md: "10%", lg: "40%", xl: "50%" },
    left: { md: "10%", lg: "40%", xl: "50%" },
    transform: "translate(-50%, -50%)",
    width: { md: "40vw", lg: 800, xl: 850 },
    height: { md: "30vh", lg: 450, xl: 470 },
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: 54,
    p: 4,
  };
  const boardUpdateStatusResetHandler = () => {
    setBoardUpdateStatus(false);
    setReFetchData(!reFetchData);
    setReFetchNewDevicesData(!reFetchNewDevicesData);
  };
  const boardUpdateStatusHandler = () => {
    setBoardUpdateStatus(true);
  };
  const handleDashboardUpdate = () => {
    SetOpenDashboardUpdate(true);
  };
  const handleCloseDashboardUpdate = () => {
    SetOpenDashboardUpdate(false);
    setOpen(null);
  };

  const dashboardDeleteStatusHandler = () => {
    setDashboardDeleteStatus(true);
  };

  const handleDashboardDelete = () => {
    SetOpenDeleteDashboardConfirm(true);
    setOpen(null);
  };

  const openDeleteDashboardConfirmResetHandler = () => {
    SetOpenDeleteDashboardConfirm(false);
  };

  const dashboardDeleteStatusResetHandler = () => {
    setDashboardDeleteStatus(false);
    setReFetchData(!reFetchData);
    setReFetchNewDevicesData(!reFetchNewDevicesData);
  };
  const handleOpenMenu = (event, rowData) => {
    setOpen(event.currentTarget);
    event.stopPropagation();
    setDataInRow(rowData);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  //----------------------------------------------------------------------------------

  const deleteDashboardHandler = useCallback(async (boardId, boardName) => {
    const operation = {
      operations_performed: "Deleted",
      component_type: "dashboard",
      component_name: boardName,
      update_by: activeAccount.name,
    };
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(BASE_URL + `/board/deleteBoard/${boardId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      if (data.Status === "success") {
        addRecentActivity(operation);
        openDeleteDashboardConfirmResetHandler();
        dashboardDeleteStatusHandler();
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              paddingTop: 5,
              color: "#022F8E",
              fontSize: { xs: "none", md: 16, lg: 20, xl: 24 },
            }}
          >
            Dashboards
          </Typography>
        </Stack>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          sx={{ width: "100vw" }}
          open={openDashboardUpdate}
          closeAfterTransition
          onClose={handleCloseDashboardUpdate}
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openDashboardUpdate}>
            <Box sx={style}>
              <AddDashboard
                onCancelClick={handleCloseDashboardUpdate}
                datas={dataInRow}
                onUpdateClick={boardUpdateStatusHandler}
              />
            </Box>
          </Fade>
        </Modal>
        {boardUpdateStatus && (
          <SuccessDialog
            open={boardUpdateStatus}
            component="Board"
            operation="added"
            onOkClick={boardUpdateStatusResetHandler}
          />
        )}
        {openDeleteDashboardConfirm && (
          <AlertDialog
            open={openDeleteDashboardConfirm}
            component="dashboard"
            operation="delete"
            name={dataInRow.boardname}
            onCancelClick={openDeleteDashboardConfirmResetHandler}
            onOkClick={() =>
              deleteDashboardHandler(dataInRow.id, dataInRow.boardname)
            }
          />
        )}
        {dashboardDeleteStatus && (
          <SuccessDialog
            open={dashboardDeleteStatus}
            component="Dashboard"
            operation="deleted"
            onOkClick={dashboardDeleteStatusResetHandler}
          />
        )}
        <ScreensInUse
          selectedTeam={props.selectedTeam}
          onPopOverMenuClick={(event, row) => handleOpenMenu(event, row)}
          reFetchData={reFetchData}
        />
        {!ctx.isRedirectFromOffice && (
          <RecentlyAddedScreens
            reFetch={reFetchNewDevicesData}
            onPopOverMenuClick={(event, row) => handleOpenMenu(event, row)}
          />
        )}
      </Box>
      <PopOverMenu
        openStatus={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
        handleUpdateOperation={handleDashboardUpdate}
        handleDeleteOperation={handleDashboardDelete}
      />
    </>
  );
}
