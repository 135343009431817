import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { env } from "../env";
export const msalConfig = {
  auth: {
    clientId: env.REACT_APP_AD_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: env.REACT_APP_AD_AUTHORITY,
    redirectUri: env.REACT_APP_URL+"/dashboard/home", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: env.REACT_APP_URL, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            /*  console.debug(message); */
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const protectedResources = {
  apiDashboard: {
    endpoint: env.REACT_APP_URL+"/dashboard/home",
    scopes: {
      read: ["api://"+env.REACT_APP_AD_CLIENT_ID+"/User.Read.All"],
    },
  },
};

export const loginRequest = {
  scopes: [...protectedResources.apiDashboard.scopes.read],
};

const instance = new PublicClientApplication(msalConfig);
export const accessTokenForApi = async () => {
  const activeAccount = instance.getAllAccounts()[0];
  var accessTokenRequest = {
    scopes: ["api://"+env.REACT_APP_AD_CLIENT_ID+"/Api.Read"],
    account: activeAccount,
  };
  if (activeAccount) {
    const accessTokenResponse = await instance.acquireTokenSilent(
      accessTokenRequest
    );
    return {
      accessToken: `${accessTokenResponse.accessToken}`,
      roles: accessTokenResponse.idTokenClaims.roles,
    };
  }
  // eslint-disable-next-line
};
export const accessTokenForProfilePic = async () => {
  const activeAccount = instance.getAllAccounts()[0];
  var accessTokenRequest = {
    scopes: ["https://graph.microsoft.com/User.Read"],
    account: activeAccount,
  };
  if (activeAccount) {
    const accessTokenResponse = await instance.acquireTokenSilent(
      accessTokenRequest
    );
    return {
      accessToken: `${accessTokenResponse.accessToken}`,
    };
  }
  // eslint-disable-next-line
};